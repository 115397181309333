import { AvailabilityStatus, TypeFee } from "./types";

export const base_url = "https://selfman-main-service-58e857dec4f7.herokuapp.com/api";
export const country_url = "https://countriesnow.space/api/v0.1/countries/"
//https://selfman-prod-service-3ef23176fdd8.herokuapp.com
export const search_url = 'https://search-selfman-mvp-3ca227881076.herokuapp.com'
// export const search_url = 'https://selfman-search-test-4edc2d9a7b30.herokuapp.com'
export const position_url = 'https://geocode.maps.co';

export const token_for_position_url = "666dd6775df5e416024886rspf4ec94"
export const TIME_FOR_UPDATE_JWT = 300000;


// Firebase
export const API_KEY_FIREBASE = "AIzaSyCqkN9j5LqX9O0t0NnX8jDQsP5jT5YI8Oo"
export const AUTH_DOMAIN = "selfmanchat.firebaseapp.com"
export const PROJECT_ID = "selfmanchat"
export const STORAGE_BUCKET = "selfmanchat.appspot.com"
export const MESSAGING_SENDER_ID = "891456178844"
export const APP_ID = "1:891456178844:web:8d9d40752c5c198e737b59"
export const DATABASE_URL = "https://selfmanchat-default-rtdb.firebaseio.com/"


// Array of agents

export const allAgentsArray = [
    {
        id: 1,
        email: '1@gmail.com',
        avatar: 'https://selfman-bucket.s3.eu-central-1.amazonaws.com/1716068178397-IMAGE_233e73b3c1-4f98-4e98-91b2-b1a3f59cab22.png',
        confirmed: true,
        password: '123',
        firstName: 'John',
        lastName: 'Smith',
        companyName: 'Smith@Co',
        languages: ['English', 'German'],
        agentContactInfo: {
            country: 'USA',
            city: 'New York',
            street: '123 Main St',
            building: 'Building A',
            zipCode: 10001,
            phoneNumber: '+1-555-555-5555'
        },
        about: '',
        priceHour: 45,
        fee: TypeFee.HOUR,
        availability: AvailabilityStatus.NOW,
        category: ['Financial services'],
        keywords: ['Translation', 'Factory verification', 'Freight forwarder', 'Contract lawyer'],
        attachments: '',
        successRate: 90,
        rating: 5,
        reviews: 625,
        socialMedia: [
            {
                name: 'instagram',
                link: ''
            },
            {
                name: 'facebook',
                link: ''
            }
        ],
        roles: [
            { name: 'AGENT' }
        ]
    },
    {
        id: 2,
        email: '2@gmail.com',
        avatar: 'https://selfman-bucket.s3.eu-central-1.amazonaws.com/1716068179295-IMAGE_23f2b55872-c854-42c1-9602-329fe2a60278.png',
        confirmed: false,
        password: '123',
        firstName: 'James',
        lastName: 'Johnson',
        companyName: 'Johnson@Co',
        languages: ['English', 'German'],
        agentContactInfo: {
            country: 'USA',
            city: 'Los Angeles',
            street: '456 Elm St',
            building: 'Building B',
            zipCode: 90001,
            phoneNumber: '+1-555-555-5556'
        },
        about: '',
        priceHour: 4500,
        fee: TypeFee.FIX,
        availability: AvailabilityStatus.NOW,
        category: ['Financial services'],
        keywords: ['Translation', 'Factory verification', 'Freight forwarder', 'Contract lawyer'],
        attachments: '',
        successRate: 95,
        rating: 4,
        reviews: 225,
        socialMedia: [
            {
                name: 'instagram',
                link: ''
            }
        ],
        roles: [
            { name: 'AGENT' }
        ]
    },
    {
        id: 3,
        email: '3@gmail.com',
        avatar: 'https://selfman-bucket.s3.eu-central-1.amazonaws.com/1716068179183-IMAGE_23e6eea889-c961-4dfb-a30d-3c9db741c856.png',
        confirmed: true,
        password: '123',
        firstName: 'Robert',
        lastName: 'Williams',
        companyName: 'Williams@Co',
        languages: ['English', 'German'],
        agentContactInfo: {
            country: 'USA',
            city: 'Chicago',
            street: '789 Pine St',
            building: 'Building C',
            zipCode: 60601,
            phoneNumber: '+1-555-555-5557'
        },
        about: '',
        priceHour: 50,
        fee: TypeFee.HOUR,
        availability: AvailabilityStatus.NOW,
        category: ['Financial services'],
        keywords: ['Translation', 'Factory verification', 'Freight forwarder', 'Contract lawyer'],
        attachments: '',
        successRate: 85,
        rating: 0,
        reviews: 150,
        socialMedia: [
            {
                name: 'instagram',
                link: ''
            },
            {
                name: 'linkedin',
                link: ''
            }
        ],
        roles: [
            { name: 'AGENT' }
        ]
    },
    {
        id: 4,
        email: '4@gmail.com',
        avatar: 'https://selfman-bucket.s3.eu-central-1.amazonaws.com/1716068178959-IMAGE_237d571511-b6a7-4075-9c6d-f7e9ce396e97.png',
        confirmed: true,
        password: '123',
        firstName: 'Michael',
        lastName: 'Brown',
        companyName: 'Brown@Co',
        languages: ['English', 'German'],
        agentContactInfo: {
            country: 'USA',
            city: 'San Francisco',
            street: '101 Market St',
            building: 'Building D',
            zipCode: 94105,
            phoneNumber: '+1-555-555-5558'
        },
        about: '',
        priceHour: 3000,
        fee: TypeFee.FIX,
        availability: AvailabilityStatus.NOW,
        category: ['Financial services'],
        keywords: ['Translation', 'Factory verification', 'Freight forwarder', 'Contract lawyer'],
        attachments: '',
        successRate: 98,
        rating: 5,
        reviews: 475,
        socialMedia: [
            {
                name: 'instagram',
                link: ''
            }
        ],
        roles: [
            { name: 'AGENT' }
        ]
    }
];





export const allAgents = {
    content: allAgentsArray,
    pageable: {
      pageNumber: 1,
      pageSize: 12,
      sort: {
        sorted: true,
        empty: false,
        unsorted: false,
      },
      offset: 1,
      paged: true,
      unpaged: false,
    },
    last: true,
    totalPages: 1,
    totalElements: 12,
    first: true,
    size: 12,
    number: 1,
    sort: {
        sorted: true,
        empty: false,
        unsorted: false,
      },
    numberOfElements: 12,
    empty: false,
  }


  export const paymentTermsFixList = [
    "Discounts for upfront payment",
    "Milestone payment",
    "P2P",
    "Payment",
    "Bitcoin",
    "Other"
  ];