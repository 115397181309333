export enum routes {
  HOME = "/",
  DELEGATESEARCH = "/delegate_search",
  DELEGATESEARCHBRIEF= "/delegate_search/brief",
  ACCOUNT = "/account/*",
  PARTNERBASE="/partner_base",
  DASHBOARD = "/dashboard",
  ASSISTANTS  = "/assistants",
  FACTORIES = "/factories",
  REQUESTS = "/requests",
  ORDERS = "/orders",
  AGENTS = "/agents",
  PROFILE = "/profile/*",
  HELP = "/help",
  CONTACT_US = "/contact-us",
  LOG_IN = "/log-in",
  // SIGN_UP = "/sign-up",
  // PERSONAL = "/personal-info",
  CONFIRMATION = "/confirmation",
  PROVIDERSETTINGS = "/provider-settings",
  PROVIDERPREVIEW = "/provider-preview",
  PARTNERBASEPROVIDER = "/partner_base-provider/:id",
  COMPANYALLPRODUCTS = "/partner_base-provider/:id/products",
  ITEMBYCATALOGPRODUCT = "/partner_base-provider/:id/products/:product_id",
  AGENTSPROVIDER = "/agents/:id",
  NETWORKSEARCH = "/network-search",
  SEARCHCONTACTPROVIDER = "/network-search/:companyName/contact/:place_id",
  PERSONALINFORMATION = "/personal-information",
  COMPANYINFORMATION = "/company-information",
  USERS = "/users",
  COMPANY = "/company",
  PRODUCTS = "/products",
  EDITPRODUCT = "/edit-product/:id",
  ADDPRODUCT = "/add-product",
  BUSINESSMATCHMAKING = "/business-matchmaking",
  DIALOG = "/dialogue/:chatId",
  NOT_FOUND = "*",
  UNDER_CONSTRUCTION = "/under-construction",
  MATCHING= "/matching",
  SPECIFICATION = "/specification",
  SOMETHING = "/something",
  COMPANYNETWORK = "/network-search/:name",
  FACTORYINFO = "/factory-info"
}
