import React from 'react'

const ArrowDownIcon = () => {
  return (
    <svg width="5.000000" height="61.000000" viewBox="0 0 5 61" fill="none" xmlns="http://www.w3.org/2000/svg">
	<desc>
			Created with Pixso.
	</desc>
	<defs/>
	<path id="Line 6" d="M1.5 56.03L1.5 0L2.5 0L2.5 56.03L4.86 56.03L2 61L-0.87 56.03L1.5 56.03Z" fill="#001731" fillOpacity="1.000000" fillRule="evenodd"/>
</svg>

  )
}

export default ArrowDownIcon