import React, { useState } from "react";
import { LeftText, OrderQuantity, Quantity } from "../ManufactoringFilter/style";
import CircleSwitch from "../../CircleSwitch/CircleSwitch";
import {
  FilterSwitchWrapper,
  Paragraph,
  ParagraphRight,
  RatingInformation,
  RatingWrapper,
  ReviewsInformation,
  StarSwitch,
  SuccesRateWrapper,
  SuccessWrapper,
  UpParagraph,
} from "./style";
import FilterSwitch from "../../FilterSwitch/FilterSwitch";
import { PartnerBaseProvider, ProviderDetails } from "../../../utils/types";

interface ReputationFilterProps {
  companyItems: PartnerBaseProvider[];
  filterValues: ProviderDetails;
  onFilterChange: (filterValues: ProviderDetails) => void;
  setFilterValues: React.Dispatch<React.SetStateAction<ProviderDetails>>;
}

const ReputationFilter: React.FC<ReputationFilterProps> = ({
  companyItems,
  setFilterValues,
  filterValues,
  onFilterChange,
}) => {
  const [verifiedFactory, setVerifiedFactory] = useState(false);
  const [certificates, setCertificates] = useState(false);
  const [selectedSuccessRate, setSelectedSuccessRate] = useState<number[]>([]);

  const handleChange = (filterName: keyof typeof filterValues) => {
    const newFilterValues = {
      ...filterValues,
      [filterName]: !filterValues[filterName],
    };
    onFilterChange(newFilterValues);
    if (filterName === "verifiedFactory") {
      setVerifiedFactory(!verifiedFactory);
    }
    if (filterName === "certification") {
      setCertificates(!certificates);
    }
  };

  const handleToggleRating = (rating: number) => {
    const currentRatings = filterValues.ratings ?? [];
    const updatedRatings = currentRatings.includes(rating)
      ? currentRatings.filter((selectedRating) => selectedRating !== rating)
      : [...currentRatings, rating];
    const newFilterValues = {
      ...filterValues,
      ratings: updatedRatings,
    };
    onFilterChange(newFilterValues);
  };

  const handleToggleReviewRange = (reviews: string) => {
    const currentReviews = filterValues.reviews ?? [];
    const updatedReviews = currentReviews.includes(reviews)
      ? currentReviews.filter((selectedReviews) => selectedReviews !== reviews)
      : [...currentReviews, reviews];

    const newFilterValues = {
      ...filterValues,
      reviews: updatedReviews,
    };
    onFilterChange(newFilterValues);
  };


  const handleToggleSuccessRate = (rate: number) => {
    const newRate = selectedSuccessRate.includes(rate)
      ? selectedSuccessRate.filter((selectedRate) => selectedRate !== rate)
      : [...selectedSuccessRate, rate];
    setSelectedSuccessRate(newRate);
    const newFilterValues = {
      ...filterValues,
      successRate: newRate,
    };
    onFilterChange(newFilterValues);
  };

  return (
    <div>
      <OrderQuantity>
        <LeftText>
        <Quantity>Verified factory:</Quantity>
        </LeftText>
        <CircleSwitch
          isActive={!!filterValues.verifiedFactory}
          onToggle={() => handleChange("verifiedFactory")}
        />
        <ParagraphRight>Yes</ParagraphRight>
      </OrderQuantity>
      <RatingInformation>
        <RatingWrapper>Rating</RatingWrapper>
        {[1, 2, 3, 4, 5].map((rating) => (
          <FilterSwitch
            key={rating}
            label={`${rating}`}
            $isActive={!!filterValues.ratings?.includes(rating)}
            onToggle={() => handleToggleRating(rating)}
            isRating={true}
          />
        ))}
      </RatingInformation>
      <ReviewsInformation>
        <RatingWrapper>Reviews</RatingWrapper>
        <StarSwitch>
          {["1-10", "11-50", "51-100", "101+"].map((range, index) => (
            <FilterSwitchWrapper key={index}>
              <FilterSwitch
                label={range}
                $isActive={!!filterValues.reviews?.includes(range)}
                onToggle={() => handleToggleReviewRange(range)}
                isRating={false}
              />
            </FilterSwitchWrapper>
          ))}
        </StarSwitch>
      </ReviewsInformation>
      <SuccesRateWrapper>
        <Quantity>Success rate</Quantity>
        <SuccessWrapper>
          <CircleSwitch
            isActive={!!filterValues.successRate?.includes(90)}
            onToggle={() => handleToggleSuccessRate(90)}
          />
          <Paragraph>90%</Paragraph>
          <UpParagraph>& Up</UpParagraph>
        </SuccessWrapper>
        <SuccessWrapper>
          <CircleSwitch
            isActive={!!filterValues.successRate?.includes(80)}
            onToggle={() => handleToggleSuccessRate(80)}
          />
          <Paragraph>80%</Paragraph>
          <UpParagraph>& Up</UpParagraph>
        </SuccessWrapper>
      </SuccesRateWrapper>
      <OrderQuantity>
        <LeftText>
        <Quantity>Certification:</Quantity>
        </LeftText>
        <CircleSwitch
          isActive={!!filterValues.certification}
          onToggle={() => handleChange("certification")}
        />
        <ParagraphRight>Yes</ParagraphRight>
      </OrderQuantity>
    </div>
  );
};

export default ReputationFilter;