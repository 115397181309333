import React, { useState } from "react";
import { RootState } from "../store/store";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import styled from "styled-components";
import MediaUpload from "../components/MediaUpload/MediaUpload";
import {
  getAllRequestsAsync,
  sendLetterToProvider,
  uploadFilesToServer,
} from "../store/slices/userSlice";
import Button from "../components/Button/Button";
import BriefList from "../components/DelegateSearchPage/BriefList/BriefList";
import { Attachment, MessageToProvider } from "../utils/types";
import TitleHeader from "../components/TitleHeader/TitleHeader";
import ContactToProviderInput from "../components/SearchContactToProviderInput/ContactToProviderInput";
import ModalLostChanges from "../components/ModalWindows/ModalLostChanges/ModalLostChanges";
import { routes } from "../routes/routes";
import ModalBrief from "../components/ModalWindows/ModalBrief/ModalBrief";
import {
  CloseUploadWrapper,
  Item,
  List,
  VectorWrapper,
} from "../components/CompanyDocuments/styles";
import CrossIcon from "../assets/icons/CrossIcon";
import VectorIcon from "../assets/icons/VectorIcon";

const Container = styled.div`
  display: grid;
  grid-template-columns: 2fr 1.3fr;
  gap: 40px;
  margin-top: 20px;
`;

const Main = styled.main``;

const TitleProviderInput = styled.input`
  width: 100%;

  padding: 10px 20px;
  margin-bottom: 18px;

  color: var(--txt-dak-light, #143255);
  border: 1px solid var(--border, #bdbdbd);
  border-radius: 12px;
  box-sizing: border-box;
  &:focus {
    border: 1px solid var(--batton, #0095c0);
  }
  &::placeholder {
    color: var(--txt-grey-light, #717880);
    font-size: 17px;
    font-weight: 400;
    line-height: 140%;
  }
`;

const FooterBlock = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`;

const MediaUploadWrapper = styled.div`
  max-width: 350px;
  width: 100%;
`;

const ButtonWrapper = styled.div`
  max-width: 250px;
  width: 100%;
`;

const InternetSearchContact: React.FC = () => {
  const user = useAppSelector((state: RootState) => state.user);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { companyName, place_id } = useParams();
  const [filesToUpload, setFilesToUpload] = useState<File[]>([]);
  const [attachments, setAttachments] = useState<Attachment[]>([]);
  const [messageToProvider, setMessageToProvider] = useState<MessageToProvider>(
    {}
  );
  const [showModal, setShowModal] = useState(false);
  const [showModalMagnifying, setShowModalMagnifying] = useState(false);
  const [selectedBrief, setSelectedBrief] = useState<number[]>([]);
  const [error, setError] = useState<boolean>(false);

  const handleAttachmentFilesChange = async (files: File[]) => {
    try {
      const saveFiles: any = await dispatch(
        uploadFilesToServer({ files: files }) as any
      );
      setAttachments((prev) => [...prev, ...saveFiles.payload]);
    } catch (error) {
      console.error(error);
    }
  };
  const handleSendLetter = () => {
    dispatch(
      sendLetterToProvider({
        ...messageToProvider,
        token: user.userAccount!.token,
        ownerMail: "",
        senderMail: "",
        attachments: attachments,
        placeUrl: "",
        briefIds: selectedBrief,
        placeId: place_id,
        companyName: companyName,
      })
    ).then((result: any) => {
      if (result.payload.message === "404") {
        setError(true);
      } else {
        setShowModal(true);
        dispatch(getAllRequestsAsync() as any);
      }
    });
  };
  const handlEditing = () => {
    setShowModal(!showModal);
    setSelectedBrief([]);
    setMessageToProvider({});
    setAttachments([]);
  };

  const handlSendAgain = () => {
    setError(false);
  };

  const handleRemoveClick = (index: number) => {
    setAttachments((prev) => prev.filter((_, i) => i !== index));
  };

  return (
    <>
      <TitleHeader text={`Contact with ${companyName}`} />
      <Container>
        <Main>
          <TitleProviderInput
            placeholder="Subject of the letter"
            value={messageToProvider?.subject || ""}
            onChange={(e) =>
              setMessageToProvider({
                ...messageToProvider,
                subject: e.target.value,
              })
            }
          />
          <ContactToProviderInput
            message={messageToProvider}
            setMessage={setMessageToProvider}
          />
          <FooterBlock>
            <MediaUploadWrapper>
              <MediaUpload
                selectedFiles={filesToUpload}
                onFilesChange={(files) => handleAttachmentFilesChange(files)}
              />
            </MediaUploadWrapper>
            <ButtonWrapper>
              <Button
                text={user.status === "loading" ? "loading..." : "Send"}
                onClick={() => handleSendLetter()}
                disabled={!messageToProvider.text || !messageToProvider.subject || user.status === "loading"}
              />
            </ButtonWrapper>
          </FooterBlock>
          <List>
            {attachments.length > 0 &&
              attachments.map((attachment, index) => {
                return (
                  <Item key={index}>
                    <VectorWrapper>
                      <VectorIcon />
                    </VectorWrapper>
                    {attachment.name}
                    <CloseUploadWrapper
                      onClick={() => handleRemoveClick(index)}
                    >
                      <CrossIcon />
                    </CloseUploadWrapper>
                  </Item>
                );
              })}
          </List>
        </Main>
        {user.delegateBrief?.content?.length !== 0 && (
          <BriefList
            setBriefId={setMessageToProvider}
            message={messageToProvider}
            selectedBrief={selectedBrief}
            setSelectedBrief={setSelectedBrief}
            setShowModalMagnifying={setShowModalMagnifying}
          />
        )}
      </Container>
      {showModal && (
        <ModalLostChanges
          text="Success! Your letter is recorded and will be sent"
          buttonText="Close page"
          buttonWhitetext="Send another message"
          handleWhiteButtonClick={handlEditing}
          handleButtonClick={() => navigate(routes.NETWORKSEARCH)}
        />
      )}
      {error && (
        <ModalLostChanges
          text="Failed! Your letter was not sent. Try again."
          buttonText="Close page"
          buttonWhitetext="Try again"
          handleWhiteButtonClick={handlSendAgain}
          handleButtonClick={() => navigate(routes.NETWORKSEARCH)}
        />
      )}
      {showModalMagnifying && (
        <ModalBrief
          brief={user.brief}
          setShowModalMagnifying={setShowModalMagnifying}
        />
      )}
    </>
  );
};

export default InternetSearchContact;
