import React from "react";
import styled, { keyframes } from "styled-components";

const animateOne = keyframes`
  0% {
    transform: translate3d(0, 0, 0);
  }
  25% {
    transform: translate3d(-6px, -4px, 0);
  }
  50% {
    transform: translate3d(0, 0, 0);
  }
  75% {
    transform: translate3d(-6px, 4px, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
`;

const animateTwo = keyframes`
  0% {
    transform: translate3d(0, 0, 0);
  }
  25% {
    transform: translate3d(6px, -4px, 0);
  }
  50% {
    transform: translate3d(0, 0, 0);
  }
  75% {
    transform: translate3d(0, -6px, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
`;

const animateThree = keyframes`
  0% {
    transform: translate3d(0, 0, 0);
  }
  25% {
    transform: translate3d(0, 6px, 0);
  }
  50% {
    transform: translate3d(0, 0, 0);
  }
  75% {
    transform: translate3d(6px, 4px, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
`;

const StyledSVG = styled.svg`
  display: inline-block;
  position: relative;
  top: 50%;
  width: 20px;
  height: 28px;
  vertical-align: middle;

  polygon {
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
    animation-fill-mode: forwards;
  }

  polygon:nth-of-type(1) {
    animation-name: ${animateOne};
  }

  polygon:nth-of-type(2) {
    animation-name: ${animateTwo};
  }

  polygon:nth-of-type(3) {
    animation-name: ${animateThree};
  }
`;

const LoadingTriangles: React.FC = () => {
  return (
    <StyledSVG
      version="1.1"
      id="logo"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="40px"
      height="48px"
      viewBox="0 0 40 48"
      enableBackground="new 0 0 40 48"
    >
      <polygon fill="#1d2327" points="21.6,12 39.6,24 21.6,36" />
      <polygon fill="#1d2327" points="0,24 18,36 0,48" />
      <polygon fill="#1d2327" points="0,0 18,12 0,24" />
    </StyledSVG>
  );
};

export default LoadingTriangles;
