import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import {
  Wrapper,
  Tittle,
  InputBlock,
  Input,
  ButtonBlock,
  KeywordsFilter,
  RequestBlock,
  SubtitleFilter,
  IconContainer,
  ButtonRating,
  ButtonReview,
  CompanyComponent,
  CompanyList,
  RatingReviewBlock,
  PaginationBlock,
  RightBlock,
  ButtonSave,
  VerticalBlock,
  ReviewComponent,
  InfoMessage,
  CompanyRoundWrapper,
  CompanyIconImg,
  RatingComponent,
  KeywordsFilterElement,
  ContactLink,
  ModalWrapper,
  ModalParagraph,
  CrossWhiteIconWrapper,
  IconWrapper,
  IconMarket,
  MarketWrapper,
  CompanyModalWrapper,
  ButtonReviewSaved,
} from "./styles";
import SearchIcon from "../../assets/icons/SearchIcon";
import PopularRequest from "../PopularRequest/PopularRequest";
import ArrowUpDownIcon from "../../assets/icons/ArrowUpDownIcon";
import { renderStars } from "../../components/CompanyItem/CompanyItem";
import Pagination from "../Pagination/Pagination";
import ArrowRightIcon from "../../assets/icons/ArrowRightIcon";
import ArrowLeftIcon from "../../assets/icons/ArrowLeftIccon";
import MoreVerticalIcon from "../../assets/icons/MoreVerticalIcon";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { setKeywordsSearch } from "../../store/slices/internetSearchSlice";
import {
  deleteFavorite,
  getAllFavorite,
  getAllFavoriteWithoutPagination,
  getFetchUrl,
  getGeocodeByCountryCity,
  replaceUnderscoreWithSpaces,
  saveClick,
  searchKeywords,
  searchTopKeywords,
} from "../../api/internetSearch";
import { Loading } from "../Loading/Loading";
import {
  FactoryInternetSearch,
  InternetSearchResponse,
} from "../../utils/types";
import { popular } from "../SearchInput/SearhInput";
import { useNavigate } from "react-router-dom";
import { routes } from "../../routes/routes";
import Button from "../Button/Button";
import CrossWhiteIcon from "../../assets/icons/CrossWhireIcon";
import MailIcon from "../../assets/icons/MailIcon";
import RedHeartIcon from "../../assets/icons/RedHeartIcon";
import HeartIcon from "../../assets/icons/HeartIcon";
import BasketIcon from "../../assets/icons/BasketIcons";
import HeartIconBlue from "../../assets/icons/HeartIconBlue";
import BasketIconBlue from "../../assets/icons/BasketIconBlue";
import MailIconBlue from "../../assets/icons/MailIconBlueIcon";
import ResizedImage from "./ResizedImage";
import ModalLostChanges from "../ModalWindows/ModalLostChanges/ModalLostChanges";

const Amazon = require("../../assets/img/amazon.png");
const Walmart = require("../../assets/img/walmart.png");
const Ebay = require("../../assets/img/ebay.png");
const Etsy = require("../../assets/img/Etsy.png");
const Mercadolibre = require("../../assets/img/MercadoLibre.png");

interface SelectedCompanyType {
  companyName: string;
  iframeUrl: string;
  website: string | null;
  place_id: string;
}

interface ListPageProps {
  companies: FactoryInternetSearch[];
  activeButton: "All" | "Saved";
  selectedCountry: string;
  selectedCity: string;
  rating: string;
  reviews: string;
  industry: string;
  setSorted: Dispatch<SetStateAction<string>>;
  sorted: string;
}

const ListPage: React.FC<ListPageProps> = ({
  companies,
  activeButton,
  selectedCountry,
  selectedCity,
  rating,
  reviews,
  industry,
  setSorted,
  sorted,
}) => {
  const [sortOrder, setSortOrder] = useState<"asc" | "desc">("asc");
  const [sortField, setSortField] = useState<
    "Location" | "Industry" | "Rating" | "Reviews" | null
  >(null);

  const [selectedCompanyId, setSelectedCompanyId] = useState<number | null>(
    null
  );
  const [iframeUrl, setIframeUrl] = useState<string | null>(null);

  const { keywordsSearch, pending } = useAppSelector(
    (state) => state.internetSearch
  );
  const internetSearchFactories = useAppSelector(
    (state) => state.internetSearch.internetSearchResponse?.content || []
  );
  const dispatch = useAppDispatch();
  const token = useAppSelector((state) => state.user.userAccount?.token);
  const favorites = useAppSelector(
    (state) => state.internetSearch.favorites?.content || []
  );
  const favoritesWithoutPagination = useAppSelector(
    (state) => state.internetSearch.allFavorites || []
  );
  const [localFavorites, setLocalFavorites] = useState<string[]>([]);
  const pendingGeocode = useAppSelector(
    (state) => state.internetSearch.pendingGeocode
  );
  const pendingFavorites = useAppSelector(
    (state) => state.internetSearch.pendingFavorites
  );
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [showCompanyModal, setShowCompanyModal] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState<SelectedCompanyType>({
    companyName: "",
    iframeUrl: "",
    website: "",
    place_id: "",
  });

  const companiesPerPage = 10;

  const page = useAppSelector(
    (state) => state.internetSearch.internetSearchResponse!.number
  );

  const pageFavorite = useAppSelector(
    (state) => state.internetSearch.favorites!.number
  );

  // const sortCompanies = () => {
  //   if (!sortField) {
  //     return companies;
  //   }
  //   return [...companies].sort((a, b) => {
  //     if (sortField === "Rating") {
  //       // Сортировка по рейтингу
  //       const order = sortOrder === "asc" ? 1 : -1;
  //       return (a.rating - b.rating) * order;
  //     } else if (sortField === "Reviews") {
  //       // Сортировка по количеству отзывов
  //       const order = sortOrder === "asc" ? 1 : -1;
  //       return (a.reviews - b.reviews) * order;
  //     } else if (sortField === "Industry") {
  //       // Сортировка по индустрии
  //       const order = sortOrder === "asc" ? 1 : -1;
  //       const arrayA = a.keywords.join(", ");
  //       const arrayB = b.keywords.join(", ");
  //       return arrayA.localeCompare(arrayB) * order;
  //     } else {
  //       // Сортировка по локации
  //       const order = sortOrder === "asc" ? 1 : -1;
  //       return (
  //         `${a.country} ${a.city}`.localeCompare(`${b.country} ${b.city}`) *
  //         order
  //       );
  //     }
  //   });
  // };

  const [hoveredIcon, setHoveredIcon] = useState<string | null>(null);

  const handleMouseEnter = (iconName: string, placeId: string) => {
    setHoveredIcon(`${iconName}-${placeId}`);
  };

  const handleMouseLeave = () => {
    setHoveredIcon(null);
  };

  const handleSortFieldChange = async (
    field: "Location" | "Industry" | "Rating" | "Reviews"
  ) => {
    const newOrder =
      field === sortField && sortOrder === "asc" ? "desc" : "asc";
    const newSorted = `${field.toLowerCase()},${newOrder}`;

    setSortField(field);
    setSortOrder(newOrder);
    setSorted(newSorted);

    await dispatch(
      getGeocodeByCountryCity(
        { country: selectedCountry, city: selectedCity },
        page,
        rating,
        reviews,
        industry,
        newSorted,
        token as string
      )
    );
  };

  const getCurrentPageCompanies = () => {
    return companies;
  };

  const getCurrentPageFavorites = () => {
    return favorites;
  };

  let totalPages = useAppSelector(
    (state) => state.internetSearch.internetSearchResponse!.totalPages
  );
  if (totalPages === 0) totalPages = 1;

  let favoritetotalPages = useAppSelector(
    (state) => state.internetSearch.favorites!.totalPages
  );
  if (favoritetotalPages === 0) favoritetotalPages = 1;

  const handlePageChange = (newPage: number) => {
    if (
      selectedCountry !== "" ||
      rating !== "" ||
      reviews !== "" ||
      industry !== ""
    ) {
      dispatch(
        getGeocodeByCountryCity(
          { country: selectedCountry, city: selectedCity },
          newPage - 1,
          rating,
          reviews,
          industry,
          "",
          token as string
        )
      );
    } else {
      dispatch(searchKeywords(token as string, newPage - 1));
    }
  };

  const handleFavoritePageChange = (newPage: number) => {
    dispatch(getAllFavorite(token as string, newPage - 1));
  };

  const goToPreviousPage = () => {
    if (page > 0) {
      dispatch(
        selectedCountry !== "" ||
          rating !== "" ||
          reviews !== "" ||
          industry !== ""
          ? getGeocodeByCountryCity(
              { country: selectedCountry, city: selectedCity },
              page - 1,
              rating,
              reviews,
              industry,
              "",
              token as string
            )
          : searchKeywords(token as string, page - 1)
      );
    }
  };

  const goToPreviousFavoritePage = async () => {
    if (pageFavorite > 0) {
      try {
        setIsLoading(true);
        await dispatch(getAllFavorite(token as string, pageFavorite - 1));
      } catch (err) {
        console.error(err);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const goToNextPage = () => {
    if (page < totalPages) {
      dispatch(
        selectedCountry !== "" ||
          rating !== "" ||
          reviews !== "" ||
          industry !== ""
          ? getGeocodeByCountryCity(
              { country: selectedCountry, city: selectedCity },
              page + 1,
              rating,
              reviews,
              industry,
              "",
              token as string
            )
          : searchKeywords(token as string, page + 1)
      );
    }
  };

  const goToNextFavoritePage = async () => {
    if (pageFavorite < favoritetotalPages) {
      try {
        setIsLoading(true);
        await dispatch(getAllFavorite(token as string, pageFavorite + 1));
      } catch (err) {
        console.error(err);
      } finally {
        setIsLoading(false);
      }
    }
  };

  // try {
  //   setIsLoading(true);
  //   await dispatch(saveClick(placeId, token ?? "") as any);
  //   await dispatch(getAllFavoriteWithoutPagination(token as string));
  //   await dispatch(getAllFavorite(token as string, pageFavorite));
  //   setSelectedCompanyId(null);
  // } catch (error) {
  //   console.error(error);
  // }
  // finally {
  //   setIsLoading(false);
  // }

  useEffect(() => {
    if (isLoading) {
      document.body.style.cursor = "wait";
    } else {
      document.body.style.cursor = "default";
    }

    return () => {
      document.body.style.cursor = "default";
    };
  }, [isLoading]);

  const handleSelectedCompanyClick = ({
    companyName,
    iframeUrl,
    website,
    place_id,
  }: SelectedCompanyType) => {
    setSelectedCompany({ companyName, iframeUrl, website, place_id });
    setShowCompanyModal(true);
  };

  const handleCompanyWebsiteClick = () => {
    setShowCompanyModal(false);
    handleNavigateClick(
      selectedCompany.companyName,
      selectedCompany.iframeUrl,
      selectedCompany.website,
      selectedCompany.place_id
    );
  };

  const handleCompanyInfoClick = () => {
    setShowCompanyModal(false);
    const url = new URL(routes.FACTORYINFO, window.location.origin);
    url.searchParams.append("companyName", selectedCompany.companyName);
    url.searchParams.append("website", selectedCompany.website || "");
    window.open(url.toString(), "_blank");
  };

  const handleNavigateClick = async (
    companyName: string,
    iframeUrl: string,
    link: string | null,
    placeId: string
  ) => {
    if (!link) {
      setIsModalOpen(true);
      setTimeout(() => {
        setIsModalOpen(false);
      }, 5000);

      return;
    }

    setIframeUrl(link);
    if (companyName) {
      const url = routes.COMPANYNETWORK.replace(
        ":name",
        companyName.toString()
      );
      const searchParams = new URLSearchParams();
      searchParams.append("url", link);

      try {
        setIsLoading(true);
        await dispatch(getFetchUrl(link, token as string));
        window.open(`${url}?${searchParams.toString()}`, "_blank");
      } finally {
        setIsLoading(false);
      }
    }
  };

  // const addFavorite = async (placeId: string) => {
  //   setLocalFavorites([...localFavorites, placeId]);
  //   try {
  //     setIsLoading(true);
  //     await dispatch(saveClick(placeId, token ?? "") as any);
  //     await dispatch(getAllFavoriteWithoutPagination(token as string));
  //     await dispatch(getAllFavorite(token as string, pageFavorite));
  //     setSelectedCompanyId(null);
  //   } catch (error) {
  //     console.error(error);
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };

  const addFavorite = async (placeId: string) => {
    setLocalFavorites([...localFavorites, placeId]);
    try {
      await dispatch(saveClick(placeId, token ?? "") as any);
      await dispatch(getAllFavoriteWithoutPagination(token as string));
      setSelectedCompanyId(null);
    } catch (error) {
      console.error(error);
    }
  };

  const deleteFavoriteItem = async (placeId: string) => {
    try {
      setLocalFavorites(localFavorites.filter((id) => id !== placeId));
      await dispatch(deleteFavorite(placeId, token ?? "") as any);
      await dispatch(getAllFavoriteWithoutPagination(token as string));

      const remainingFavorites = favoritesWithoutPagination.length - 1;
      const newTotalPages = Math.ceil(remainingFavorites / companiesPerPage);
      setSelectedCompanyId(null);
    } catch (error) {
      console.error(error);
    }
  };

  const limitWords = (str: string, limit: number): string => {
    return str.split(",").slice(0, limit).join(",");
  };

  useEffect(() => {
    setLocalFavorites(
      favoritesWithoutPagination?.map((favorite) => favorite.place_id)
    );
  }, [favorites]);

  return (
    <Wrapper>
      <KeywordsFilter>
        <SubtitleFilter>#</SubtitleFilter>
        <SubtitleFilter>Company name</SubtitleFilter>
        <KeywordsFilterElement>
          <SubtitleFilter>Location</SubtitleFilter>
          {activeButton === "All" && (
            <IconContainer onClick={() => handleSortFieldChange("Location")}>
              <ArrowUpDownIcon />
            </IconContainer>
          )}
        </KeywordsFilterElement>
        <KeywordsFilterElement>
          <SubtitleFilter>Industry</SubtitleFilter>
          {activeButton === "All" && (
            <IconContainer onClick={() => handleSortFieldChange("Industry")}>
              <ArrowUpDownIcon />
            </IconContainer>
          )}
        </KeywordsFilterElement>
        <SubtitleFilter>Market place</SubtitleFilter>
        {activeButton === "All" ? (
          <KeywordsFilterElement>
            <ButtonRating onClick={() => handleSortFieldChange("Rating")}>
              Rating
            </ButtonRating>
            <p>/</p>
            <ButtonReview onClick={() => handleSortFieldChange("Reviews")}>
              Reviews
            </ButtonReview>
          </KeywordsFilterElement>
        ) : (
          <KeywordsFilterElement>
            <ButtonReviewSaved>Rating</ButtonReviewSaved>
            <p>/</p>
            <ButtonReviewSaved>Reviews</ButtonReviewSaved>
          </KeywordsFilterElement>
        )}
      </KeywordsFilter>
      {pending || pendingGeocode || pendingFavorites ? (
        <Loading />
      ) : internetSearchFactories.length === 0 ? (
        <InfoMessage>{`No results found for your request "${keywordsSearch}". Please try again.`}</InfoMessage>
      ) : (
        (activeButton === "Saved"
          ? getCurrentPageFavorites()
          : getCurrentPageCompanies()
        ).map(
          (
            {
              place_id,
              companyName,
              country,
              city,
              industry,
              website,
              logo,
              rating,
              reviews,
            },
            i
          ) => (
            <CompanyList key={place_id}>
              {activeButton === "All" ? (
                <CompanyComponent>
                  {i + 1 + page * companiesPerPage}
                </CompanyComponent>
              ) : (
                <CompanyComponent>
                  {i + 1 + pageFavorite * companiesPerPage}
                </CompanyComponent>
              )}
              <CompanyRoundWrapper>
                <ResizedImage src={logo} />
              </CompanyRoundWrapper>
              <CompanyComponent
                onClick={() =>
                  handleSelectedCompanyClick({
                    companyName,
                    iframeUrl: iframeUrl || "",
                    website,
                    place_id,
                  })
                }
              >
                {companyName}
              </CompanyComponent>
              <CompanyComponent>{`${country} ${city}`}</CompanyComponent>
              <CompanyComponent>
                {replaceUnderscoreWithSpaces(
                  limitWords(industry.join(", "), 3)
                )}
              </CompanyComponent>
              <CompanyComponent>
                <MarketWrapper>
                  <IconMarket src={Walmart} />
                  <IconMarket src={Ebay} />
                  <IconMarket src={Amazon} />
                  <IconMarket src={Etsy} />
                  <IconMarket src={Mercadolibre} />
                </MarketWrapper>
              </CompanyComponent>
              <RightBlock>
                <RatingReviewBlock>
                  <ReviewComponent>{reviews}</ReviewComponent>
                  <RatingComponent>
                    {renderStars(Math.round(rating ? rating : 0))}
                  </RatingComponent>
                </RatingReviewBlock>
              </RightBlock>
              {activeButton === "All" ? (
                localFavorites.includes(place_id) ? (
                  <VerticalBlock>
                    <IconWrapper onClick={() => deleteFavoriteItem(place_id)}>
                      <RedHeartIcon />
                    </IconWrapper>
                  </VerticalBlock>
                ) : (
                  <VerticalBlock>
                    <IconWrapper
                      onClick={() => addFavorite(place_id)}
                      onMouseEnter={() => handleMouseEnter("heart", place_id)}
                      onMouseLeave={handleMouseLeave}
                    >
                      {hoveredIcon === `heart-${place_id}` ? (
                        <HeartIconBlue />
                      ) : (
                        <HeartIcon />
                      )}
                    </IconWrapper>
                  </VerticalBlock>
                )
              ) : (
                <VerticalBlock>
                  <IconWrapper
                    onClick={() => deleteFavoriteItem(place_id)}
                    onMouseEnter={() => handleMouseEnter("basket", place_id)}
                    onMouseLeave={handleMouseLeave}
                  >
                    {hoveredIcon === `basket-${place_id}` ? (
                      <BasketIconBlue />
                    ) : (
                      <BasketIcon />
                    )}
                  </IconWrapper>
                </VerticalBlock>
              )}

              <VerticalBlock>
                <ContactLink
                  to={{
                    pathname: `/network-search/${companyName}/contact/${place_id}`,
                  }}
                  target="_blank"
                >
                  <IconWrapper
                    onMouseEnter={() => handleMouseEnter("mail", place_id)}
                    onMouseLeave={handleMouseLeave}
                  >
                    {hoveredIcon === `mail-${place_id}` ? (
                      <MailIconBlue />
                    ) : (
                      <MailIcon />
                    )}
                  </IconWrapper>
                </ContactLink>
              </VerticalBlock>
            </CompanyList>
          )
        )
      )}
      {activeButton === "Saved" && localFavorites.length === 0 && (
        <InfoMessage>No saved companies.</InfoMessage>
      )}

      {isModalOpen && (
        <ModalWrapper>
          <ModalParagraph>This company does not have a website.</ModalParagraph>
          <CrossWhiteIconWrapper onClick={() => setIsModalOpen(!isModalOpen)}>
            <CrossWhiteIcon />
          </CrossWhiteIconWrapper>
        </ModalWrapper>
      )}
      <PaginationBlock>
        <ArrowLeftIcon
          onClick={
            activeButton === "All" ? goToPreviousPage : goToPreviousFavoritePage
          }
        />
        <Pagination
          currentPage={activeButton === "All" ? page + 1 : pageFavorite + 1}
          totalPages={activeButton === "All" ? totalPages : favoritetotalPages}
          onPageChange={
            activeButton === "All" ? handlePageChange : handleFavoritePageChange
          }
        />
        <ArrowRightIcon
          onClick={activeButton === "All" ? goToNextPage : goToNextFavoritePage}
        />
      </PaginationBlock>

      {showCompanyModal && (
        <CompanyModalWrapper>
          <ModalLostChanges
            buttonText="Company's website"
            buttonWhitetext="Company info"
            text="What do you prefer to view?"
            handleButtonClick={() => handleCompanyWebsiteClick()}
            handleWhiteButtonClick={() => handleCompanyInfoClick()}
          />
        </CompanyModalWrapper>
      )}
    </Wrapper>
  );
};

export default ListPage;
