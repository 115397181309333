import React, { useEffect, useRef, useState } from "react";
import EmojiPicker, { EmojiClickData } from "emoji-picker-react";
import { v4 as uuidv4 } from "uuid";
import { onValue, ref } from "firebase/database";
import {
  AttachmentsWrapper,
  ButtonBottomContainer,
  ButtonBottomWrapper,
  ButtonText,
  ButtonWrapper,
  Container,
  IconButtonWrapper,
  RequestContainer,
  SendMessageConainer,
  Star,
  SubTitle,
  SubtitleWrapper,
  TitleWrapper,
  UserMessageInput,
  MessageContainer,
  MessageList,
  MediaUploadWrapper,
  UploadLoadingWrapper,
  UploadLoadingText,
  OrderRequestWrapper,
  OrderWrapper,
  TitleOrder,
  SubOrderWrapper,
  ParagraphWrapper,
  NameParagraph,
  AnswerParagraph,
  OrderStatusWrapper,
  CheckIconWrapper,
  OrderCheckMark,
  OrderStatusContentWrapperContainer,
  OrderStatusContentWrapper,
  OrderContentDateComponent,
  CreateOrderButtonWrapper,
  ButtonCreateWrapper,
  NotActiveOrderCheckMark,
  NotActiveOrderStatusContentWrapper,
  LeftWrapper,
  Overlay,
  DialogContent,
  Tittle,
  ConnectWrapper,
  NameLogoWrapper,
  Avatar,
  ConnectParagraph,
  YourMessageWrapper,
  ButtonCancelSendWrapper,
  CancelButton,
  ButtonSendWrapper,
  MessageArea,
  NameMessageArea,
  UserMessageWrapper,
  EmojiWrapper,
  Anchor,
  LoadingMessagesWrapper,
  LoadingText,
  LoadingBlock,
} from "./styles";
import TitleHeader from "../TitleHeader/TitleHeader";
import { useParams } from "react-router-dom";
import {
  getDialogueMailByIdAsync,
  SendMessageAsync,
  sendMessageToEmail,
  uploadFilesToServer,
} from "../../store/slices/userSlice";
import { RootState } from "../../store/store";
import { Attachment, Message, Requst } from "../../utils/types";
import CallIcon from "../../assets/icons/CallIcon";
import VideoCallIcon from "../../assets/icons/VideoCallIcon";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import Button from "../Button/Button";
import ButtonWhite from "../ButtonWhite/ButtonWhite";
import DialogueMessages from "./DialogueMessages/DialogueMessages";
import CompanionsNames from "./CompanionsNames/CompanionsNames";
import CheckIcon from "../../assets/icons/CheckIcon";
import ArrowDownIcon from "../../assets/icons/ArrowDownIcon";
import EmojiIcon from "../../assets/icons/EmojiIcon";
import { db } from "../../utils/firebase";
import {
  CloseUploadWrapper,
  Item,
  List,
  VectorWrapper,
} from "../CompanyDocuments/styles";
import VectorIcon from "../../assets/icons/VectorIcon";
import CrossIcon from "../../assets/icons/CrossIcon";
import MediaUpload from "../MediaUpload/MediaUpload";
import LoadingTriangles from "../../assets/icons/LoadingTriangles";

const Dialogue: React.FC = () => {
  const dispatch = useAppDispatch();
  const user = useAppSelector((state: RootState) => state.user);
  const [message, setMessage] = useState("");
  const [chatMessages, setChatMessages] = useState<Message[]>([]);
  const [open, setOpen] = useState<boolean>(false);
  const [attachments, setAttachments] = useState<Attachment[]>([]);
  const [filesToUpload, setFilesToUpload] = useState<File[]>([]);
  const endRef = useRef<HTMLDivElement | null>(null);
  const { chatId } = useParams(); // requestId
  const requests: Requst[] = useAppSelector(
    (state) => state.user.requests?.content ?? []
  );
  const dialog = requests.find(
    ({ chatId: requestChatId }) => requestChatId === chatId
  );

  const zonedDateTime = new Date();
  const uniqueKey = zonedDateTime.getTime().toString();

  const sendMessage = () => {
    dialog?.recipientEmail
      ? dispatch(
          sendMessageToEmail({
            body: message,
            attachments: attachments,
            id: dialog.id,
          })
        )
      : dispatch(
          SendMessageAsync({
            id: uuidv4(),
            text: message,
            dateCreated: new Date().toISOString(),
            ownerId: user.userAccount?.id!,
            orderId: 0,
            email: user.userAccount?.email!,
            chatId: chatId!,
            files: attachments,
            uniqueKey: uniqueKey,
          })
        );
    setMessage("");
    setAttachments([]);
  };
  useEffect(() => {
    const fetchDialogue = async () => {
      if (dialog?.recipientEmail) {
        await dispatch(
          getDialogueMailByIdAsync({
            id: dialog?.id,
          })
        );
      }
    };
    fetchDialogue();
    const intervalId = setInterval(fetchDialogue, 1 * 60 * 1000);
    return () => clearInterval(intervalId);
  }, [dialog]);

  const handleAttachmentFilesChange = async (files: File[]) => {
    try {
      const saveFiles: any = await dispatch(
        uploadFilesToServer({ files: files }) as any
      );
      setAttachments((prev) => [...prev, ...saveFiles.payload]);
    } catch (error) {
      console.error(error);
    }
  };

  const handleEmojiClick = (e: EmojiClickData) => {
    setMessage((prev) => prev + e.emoji);
    setOpen(false);
  };

  const handleRemoveClick = (index: number) => {
    setAttachments((prev) => prev.filter((_, i) => i !== index));
  };

  useEffect(() => {
    endRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [chatMessages]);

  useEffect(() => {
    if (chatId) {
      const messagesRef = ref(db, `chats/${chatId}/messages`);
      const unsubscribe = onValue(messagesRef, (snapshot) => {
        const data = snapshot.val();
        if (data) {
          const messagesArray: Message[] = Object.values(data);
          setChatMessages(messagesArray);
        } else {
          setChatMessages([]);
        }
      });
      return () => unsubscribe();
    }
  }, [chatId, db]);

  return (
    <Container>
      <OrderRequestWrapper>
        <LeftWrapper>
          <TitleWrapper>
            <TitleHeader text={"Business requests"} />
          </TitleWrapper>
          <ButtonWrapper>
            <IconButtonWrapper>
              <ButtonText>Call</ButtonText>
              <CallIcon />
            </IconButtonWrapper>
            <IconButtonWrapper>
              <ButtonText>Video call</ButtonText>
              <VideoCallIcon />
            </IconButtonWrapper>
          </ButtonWrapper>
          <RequestContainer>
            {user.status === "loading" ? (
              <LoadingMessagesWrapper>
                <LoadingText>Loading messages from mail...</LoadingText>
                <LoadingTriangles />
              </LoadingMessagesWrapper>
            ) : (
              <LoadingBlock />
            )}

            <MessageList>
              {chatMessages &&
                chatMessages?.map(
                  ({
                    text,
                    id,
                    files,
                    dateCreated,
                    ownerId,
                    orderId,
                    email,
                  }) => (
                    <MessageContainer key={id}>
                      <CompanionsNames
                        side={
                          email === user.userAccount?.email ? "right" : "left"
                        }
                        userInfo={dialog}
                      />
                      <DialogueMessages
                        text={text}
                        files={files}
                        dateCreated={dateCreated}
                        side={
                          email === user.userAccount?.email ? "right" : "left"
                        }
                        ownerId={ownerId}
                        currentId={user.userAccount?.id ?? 0}
                        orderId={orderId}
                      />
                    </MessageContainer>
                  )
                )}
              <Anchor ref={endRef}></Anchor>
            </MessageList>
            <SendMessageConainer>
              <SubtitleWrapper>
                <SubTitle>Your message</SubTitle>
                <Star>*</Star>
              </SubtitleWrapper>
              <UserMessageWrapper>
                <UserMessageInput
                  placeholder="Text..."
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      sendMessage();
                    }
                  }}
                />
                <EmojiIcon onClick={() => setOpen((prev) => !prev)} />
                <EmojiWrapper>
                  <EmojiPicker open={open} onEmojiClick={handleEmojiClick} />
                </EmojiWrapper>
              </UserMessageWrapper>
              <AttachmentsWrapper>
                <MediaUploadWrapper>
                  <MediaUpload
                    selectedFiles={filesToUpload}
                    onFilesChange={handleAttachmentFilesChange}
                  />
                </MediaUploadWrapper>
                <ButtonBottomContainer>
                  <ButtonBottomWrapper>
                    <ButtonWhite text={"Cancel"} />
                  </ButtonBottomWrapper>
                  <Button
                    onClick={sendMessage}
                    text={user.status === "loading" ? "loading..." : "Send"}
                    disabled={
                      user.status === "loading" ||
                      (!message && attachments.length === 0)
                    }
                  />
                </ButtonBottomContainer>
              </AttachmentsWrapper>
              <List>
                {attachments.length > 0 &&
                  attachments.map((attachment, index) => {
                    return (
                      <Item key={index}>
                        <VectorWrapper>
                          <VectorIcon />
                        </VectorWrapper>
                        {attachment.name}
                        <CloseUploadWrapper
                          onClick={() => handleRemoveClick(index)}
                        >
                          <CrossIcon />
                        </CloseUploadWrapper>
                      </Item>
                    );
                  })}
              </List>
            </SendMessageConainer>
          </RequestContainer>
        </LeftWrapper>
        <OrderWrapper>
          <TitleOrder>Create your order</TitleOrder>
          <SubOrderWrapper>
            <ParagraphWrapper>
              <NameParagraph>Order number:</NameParagraph>
              <AnswerParagraph>№ FA-853-38433</AnswerParagraph>
            </ParagraphWrapper>
            <ParagraphWrapper>
              <NameParagraph>Vendor:</NameParagraph>
              <AnswerParagraph>Electro Solutions</AnswerParagraph>
            </ParagraphWrapper>
          </SubOrderWrapper>
          <OrderStatusWrapper>
            <OrderCheckMark>
              <CheckIconWrapper>
                <CheckIcon />
              </CheckIconWrapper>
            </OrderCheckMark>
            <OrderStatusContentWrapperContainer>
              <OrderStatusContentWrapper>
                Create order
              </OrderStatusContentWrapper>
              <OrderContentDateComponent>23 May 2024</OrderContentDateComponent>
            </OrderStatusContentWrapperContainer>
          </OrderStatusWrapper>
          <CreateOrderButtonWrapper>
            <ArrowDownIcon />
            <ButtonCreateWrapper>
              <Button text="Create order" />
            </ButtonCreateWrapper>
          </CreateOrderButtonWrapper>
          <OrderStatusWrapper>
            <NotActiveOrderCheckMark />
            <OrderStatusContentWrapperContainer>
              <NotActiveOrderStatusContentWrapper>
                Documents signed
              </NotActiveOrderStatusContentWrapper>
            </OrderStatusContentWrapperContainer>
          </OrderStatusWrapper>
          <OrderStatusWrapper>
            <NotActiveOrderCheckMark />
            <OrderStatusContentWrapperContainer>
              <NotActiveOrderStatusContentWrapper>
                Order manufactored
              </NotActiveOrderStatusContentWrapper>
            </OrderStatusContentWrapperContainer>
          </OrderStatusWrapper>
          <OrderStatusWrapper>
            <NotActiveOrderCheckMark />
            <OrderStatusContentWrapperContainer>
              <NotActiveOrderStatusContentWrapper>
                Order send
              </NotActiveOrderStatusContentWrapper>
            </OrderStatusContentWrapperContainer>
          </OrderStatusWrapper>
          <OrderStatusWrapper>
            <NotActiveOrderCheckMark />
            <OrderStatusContentWrapperContainer>
              <NotActiveOrderStatusContentWrapper>
                Order received & accepted
              </NotActiveOrderStatusContentWrapper>
            </OrderStatusContentWrapperContainer>
          </OrderStatusWrapper>
        </OrderWrapper>
      </OrderRequestWrapper>
    </Container>
  );
};

export default Dialogue;
