import React from "react";
import styled from "styled-components";
import ReturnButton from "../components/ReturnButton/ReturnButton";
import Dialogue from "../components/Dialogue/Dialogue";
import { useBlocker } from "react-router-dom";
import { useAppDispatch } from "../store/hooks";
import { resetPaginationRequestPage } from "../store/slices/userSlice";

const ButtonWrapper = styled.div`
  margin-bottom: 30px;
`;
const DialoguePage: React.FC = () => {
  const dispatch = useAppDispatch();

  useBlocker(({ nextLocation }) => {
    if (!nextLocation.pathname.includes("/requests")) {
      dispatch(resetPaginationRequestPage());
    }
    return false;
  });

  return (
    <>
      <ButtonWrapper>
        <ReturnButton />
      </ButtonWrapper>
      <Dialogue />
    </>
  );
};

export default DialoguePage;
