import React, { useEffect, useState } from "react";
import DelegateSearchPage from "../components/DelegateSearchPage/DelegateSearchPages";
import { useAppSelector } from "../store/hooks";
import { RootState } from "../store/store";
import { useNavigate } from "react-router-dom";
import { routes } from "../routes/routes";
import styled from "styled-components";
import BriefList from "../components/DelegateSearchPage/BriefList/BriefList";
import { DelegateSearchType } from "../utils/types";

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 795px 800px;
  gap: 250px;
  align-items: start;
`;

const DelegateSearch: React.FC = () => {
  const user = useAppSelector((state: RootState) => state.user);
  const navigate = useNavigate();

  const [delegateSearch, setDelegateSearch] = useState<DelegateSearchType>({});

  useEffect(() => {
    if (!user.userAccount) {
      navigate(routes.HOME);
    }
  }, [user, navigate]);
  return (
    <Wrapper>
      <DelegateSearchPage
        delegateSearch={delegateSearch}
        setDelegateSearch={setDelegateSearch}
      />
      {user.delegateBrief?.content?.length !== 0 && (
        <BriefList
          delegateSearch={delegateSearch}
          setDelegateSearch={setDelegateSearch}
        />
      )}
    </Wrapper>
  );
};

export default DelegateSearch;
