import { useEffect, useState } from "react";
import VectorIcon from "../../../assets/icons/VectorIcon";
import { Attachment, Order, OrderStatus } from "../../../utils/types";
import ButtonWhite from "../../ButtonWhite/ButtonWhite";
import {
  ButtonMessageWrapper,
  Download,
  Item,
  ListFile,
  MessageContainer,
  MessageDataSide,
  MessageText,
  MessageWrapper,
  MessageWrapperSide,
  OrderMessageBottomTitle,
  OrderMessageContainer,
  OrderMessageSubTitle,
  OrderMessageText,
  OrderMessageTitle,
  VectorWrapper,
} from "../styles";
import {
  getAllOrdersAsync,
  getOrderByIdAsync,
  updateOrderStatusAsync,
} from "../../../store/slices/userSlice";
import { useAppSelector } from "../../../store/hooks";
import { useDispatch } from "react-redux";

interface Props {
  text: string;
  files?: Attachment[];
  dateCreated: string;
  side: string;
  ownerId: number;
  currentId: number;
  orderId: number;
  dialogueOrders?: Order[];
}

const DialogueMessages = ({
  text,
  files,
  dateCreated,
  side,
  ownerId,
  currentId,
  orderId,
  dialogueOrders,
}: Props) => {
  const isOrderMessage = text?.startsWith("Order name:");
  const token = useAppSelector((store) => store.user.userAccount?.token!);
  const order = dialogueOrders?.find((order) => order.id === orderId); // if order has been "Rejected", that means that it was deleted and we can't show its status OrderStatus.CANCELED

  const dispatch = useDispatch();

  const formatDate = (dateCreated: string) => {
    const dateObj = new Date(dateCreated);
    const timeString = dateObj.toLocaleTimeString("en-US", {
      hour12: false,
      hour: "2-digit",
      minute: "2-digit",
    });
    const dateString = dateObj.toLocaleDateString("en-US", {
      day: "2-digit",
      month: "2-digit",
      year: "2-digit",
    });
    return `${timeString} ${dateString}`;
  };

  const updateOrderStatus = (
    orderId: number,
    token: string,
    orderStatus: OrderStatus
  ) => {
    dispatch(
      updateOrderStatusAsync({
        processingStatus: orderStatus,
        orderId: orderId,
        token: token,
      }) as any
    ).then(() =>
      // dispatch(getOrderByIdAsync({ id: orderId, token: token }) as any)
      dispatch(getAllOrdersAsync() as any)
    ) as any;
  };

  const renderOrderMessage = (text: string, orderId: number) => {
    const [orderName, technicalSpecifications] = text.split("\n");
    return (
      <>
        <OrderMessageText>
          <OrderMessageTitle>New order</OrderMessageTitle>
          <OrderMessageSubTitle>
            {orderName.split(":")[0]}:
          </OrderMessageSubTitle>
          {orderName.split(":")[1]}
        </OrderMessageText>
        <OrderMessageText>
          <OrderMessageSubTitle>
            {technicalSpecifications.split(":")[0]}:
          </OrderMessageSubTitle>
          {technicalSpecifications.split(":")[1]}
        </OrderMessageText>
        {ownerId === currentId ? (
          <OrderMessageBottomTitle>
            The order is awaiting confirmation
          </OrderMessageBottomTitle>
        ) : (
          <ButtonMessageWrapper>
            <ButtonWhite
              text={"Accept"}
              onClick={() => {
                updateOrderStatus(orderId, token, OrderStatus.CONFIRMED);
              }}
            />
            <ButtonWhite
              text={"Reject"}
              onClick={() => {
                updateOrderStatus(orderId, token, OrderStatus.CANCELED);
              }}
            />
          </ButtonMessageWrapper>
        )}
      </>
    );
  };

  return (
    <MessageContainer>
      <MessageWrapperSide $side={side}>
        {order || isOrderMessage ? (
          <>
            <OrderMessageContainer>
              {order?.processingStatuses &&
              order?.processingStatuses.length > 1 ? (
                <OrderMessageBottomTitle>
                  {order?.processingStatuses[1]?.type === OrderStatus.CONFIRMED
                    ? "Order received successfully"
                    : "Order cancelled successfully"}
                </OrderMessageBottomTitle>
              ) : (
                renderOrderMessage(text, orderId)
              )}
            </OrderMessageContainer>
          </>
        ) : (
          <MessageWrapper>
            <MessageText>{text}</MessageText>
            {files && files?.length > 0 && (
              <ListFile>
                {files?.map((file, index) => (
                  <Item key={index}>
                    <VectorWrapper>
                      <VectorIcon />
                    </VectorWrapper>
                    <Download href={file.link} download={file.name}>
                      {file.name}
                    </Download>
                  </Item>
                ))}
              </ListFile>
            )}
          </MessageWrapper>
        )}
      </MessageWrapperSide>
      <MessageDataSide $side={side}>{formatDate(dateCreated)}</MessageDataSide>
    </MessageContainer>
  );
};

export default DialogueMessages;
