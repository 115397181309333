import React, { useEffect, useState } from "react";
import CircleSwitch from "../../CircleSwitch/CircleSwitch";
import {
    Paragraph,
    RatingInformation,
    RatingWrapper,
    ReviewsInformation,
    StarSwitch,
    SuccesRateWrapper,
    SuccessWrapper,
    UpParagraph,
} from "./style";
import FilterSwitch from "../../FilterSwitch/FilterSwitch";
import {
    OrderQuantity,
    Quantity,
    SuccessRateTitle,
} from "../../PartnerBasePage/ManufactoringFilter/style";
import {
    AgentBaseAgent,
    AgentDetails,
    AvailabilityStatus,
    PartnerBaseProvider,
    ProviderDetails,
    TypeFee
} from "../../../utils/types";

interface ReputationFilterProps {
    companyItems: AgentBaseAgent[];
    filterValues: AgentDetails;
    onFilterChange: (filterValues: AgentDetails) => void;
    setFilterValues: React.Dispatch<React.SetStateAction<AgentDetails>>;

}

const ReputationFilter: React.FC<
    ReputationFilterProps
> = ({ companyItems, filterValues, setFilterValues, onFilterChange }) => {


    if (filterValues?.confirmed === undefined || filterValues?.ratings === undefined || filterValues.reviews === undefined || filterValues.successRate === undefined) {
        setFilterValues({ confirmed: false, ratings: [], reviews: [], successRate: 0 });
    }
    return (
        <div>
            <OrderQuantity>
                <Quantity>Verified agent:</Quantity>
                <div style={{display: 'flex', alignItems: 'center'}} >
                    <CircleSwitch
                        isActive={filterValues?.confirmed || false}
                        onToggle={(e) => setFilterValues({ ...filterValues, confirmed: !filterValues?.confirmed })}
                    />
                    <Paragraph>Yes</Paragraph>
                </div>
            </OrderQuantity>
            <RatingInformation>
                <RatingWrapper>Rating</RatingWrapper>
                <StarSwitch></StarSwitch>
                {[1, 2, 3, 4, 5].map((rating) => (
                    <FilterSwitch
                        key={rating}
                        label={`${rating}`}
                        $isActive={filterValues?.ratings?.includes(rating) || false}
                        onToggle={() => {
                            if (filterValues?.ratings?.includes(rating)) {
                                setFilterValues({
                                    ...filterValues,
                                    ratings: filterValues!.ratings!.filter((r) => r !== rating),
                                });
                            } else {
                                setFilterValues({
                                    ...filterValues,
                                    ratings: [...(filterValues!.ratings || []), rating],
                                });
                            }
                        }}
                        isRating={true}
                    />
                ))}
            </RatingInformation>
            <ReviewsInformation>
                <RatingWrapper>Reviews</RatingWrapper>
                <StarSwitch>
                    {[
                        {label: '1-10', value: 1}, 
                        {label: '11-50', value: 10}, 
                        {label: '51-100', value: 50}, 
                        {label: '101+', value: 100}].map((reviews) => (
                        <FilterSwitch
                            key={reviews.value}
                            label={`${reviews.label}`}
                            $isActive={filterValues?.reviews?.includes(reviews.value) || false}
                            onToggle={() => {
                                if (filterValues?.reviews?.includes(reviews.value)) {
                                    setFilterValues({
                                        ...filterValues,
                                        reviews: filterValues!.reviews!.filter((r) => r !== reviews.value),
                                    });
                                } else {
                                    setFilterValues({
                                        ...filterValues,
                                        // передается массив объектов! исправить!
                                        reviews: [...(filterValues!.reviews || []), reviews.value],
                                    });
                                }
                            }}
                            isRating={false}
                        />
                    ))}
                </StarSwitch>
            </ReviewsInformation>
            <SuccesRateWrapper>
                <SuccessRateTitle>Success rate</SuccessRateTitle>
                <SuccessWrapper>
                    <CircleSwitch
                        isActive={filterValues?.successRate === 80 || false}
                        onToggle={(e) => setFilterValues({ ...filterValues, successRate: 80 })}
                    />
                    <Paragraph>90%</Paragraph>
                    <UpParagraph>& Up</UpParagraph>
                </SuccessWrapper>
                <SuccessWrapper>
                    <CircleSwitch
                        isActive={filterValues?.successRate === 90 || false}
                        onToggle={(e) => setFilterValues({ ...filterValues, successRate: 90 })}
                    />
                    <Paragraph>80%</Paragraph>
                    <UpParagraph>& Up</UpParagraph>
                </SuccessWrapper>
            </SuccesRateWrapper>
        </div>
    );
};

export default ReputationFilter;
