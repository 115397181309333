import React, { useEffect, useState } from "react";
import {
  ButtonCancelSendWrapper,
  ButtonSendWrapper,
  CancelButton,
  ChevronDownSortWrapper,
  ChevronRightSortWrapper,
  ChevronRightWrapper,
  ChevronUpWrapper,
  ConnectParagraph,
  ConnectWrapper,
  Container,
  CrossWhiteIconWrapper,
  DialogContent,
  IndustryLocationWrapper,
  LocationIndustryButton,
  Message,
  NameLogoWrapper,
  PageActiveButton,
  PageActiveItem,
  PageBlock,
  PageButton,
  PageWrapper,
  PaginationBlock,
  PaginationContainer,
  RedStar,
  SaveParagraph,
  SaveWrapper,
  SortActiveButton,
  SortActiveWrapper,
  SortButton,
  SortContainer,
  SortWrapper,
  Tittle,
  YourMessageWrapper,
  TextPage,
  Avatar,
  MediaUploadWrapper,
  NoResultsMessage,
  Overlay,
} from "./style";
import Button from "../../Button/Button";
import ArrowLeftIcon from "../../../assets/icons/ArrowLeftIccon";
import Pagination from "../../Pagination/Pagination";
import ArrowRightIcon from "../../../assets/icons/ArrowRightIcon";
import ChevronRightIcon from "../../../assets/icons/ChevronRightIcon";
import ChevronDownIcon from "../../../assets/icons/ChevronDownIcon";
import ChevronUpIcon from "../../../assets/icons/ChevronUpIcon";
import CrossWhiteIcon from "../../../assets/icons/CrossWhireIcon";

import {
  Attachment,
  ConnectionData,
  ModifiedFileObject,
  PartnerBaseProvider,
  ProviderDetails,
  UserAccount,
  allProvidersResponse,
} from "../../../utils/types";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { defaultAvatar } from "../../../assets/img";
import MediaUpload from "../../MediaUpload/MediaUpload";
import {
  createBusinessRequestsAsync,
  getAllRequestsAsync,
  getFavoriteProvidersAsync,
  uploadFilesToServer,
} from "../../../store/slices/userSlice";
import { useDispatch } from "react-redux";
import { changePartnerBase } from "../../../store/slices/sliceData";
import PartnerBaseCardToList from "../PartnerBaseCardToList/PartnerBaseCardToList";
import { RootState } from "../../../store/store";

interface ProviderListProps {
  filterValues: ProviderDetails;
  setFilterValues: React.Dispatch<React.SetStateAction<ProviderDetails>>;
  allProviders: allProvidersResponse;
  handleApplyFilter: () => void;
  searchValue: string;
}


const PartnerBaseList: React.FC<ProviderListProps> = ({
  setFilterValues,
  handleApplyFilter,
  filterValues,
  allProviders,
  searchValue
}) => {
  const dispatch = useAppDispatch();
  const pageDispatch = useDispatch();
  // const allProviders: PartnerBaseProvider[] = useAppSelector(
  //   (state) => state.user.allProviders || []
  // );
  const favoriteProviders = useAppSelector(
    (state) => state.user.favoriteProviders ? state.user.favoriteProviders.content : []
  );
  const page = useAppSelector((state) => state.themes.partnerBaseFavorites);
  const token = useAppSelector((state) => state.user.userAccount?.token!);

  const [currentPage, setCurrentPage] = useState(1);
  const [selectedCompaniesPerPage, setSelectedCompaniesPerPage] = useState(12);
  const [showSortOptions, setShowSortOptions] = useState(false);
  const [selectedSortOption, setSelectedSortOption] = useState("Sort by");
  const [showSendMessage, setShowSendMessage] = useState(false);
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  // const [messageFocus, setMessageFocus] = useState(false);
  const [selectedSortCategory, setSelectedSortCategory] = useState<string>("");
  const [selectedCompany, setSelectedCompany] = useState<PartnerBaseProvider>();
  const [uploading, setUploading] = useState(false);
  const [filesToUpload, setFilesToUpload] = useState<File[]>([]);
  const itemsPerPageOptions = [48, 36, 24, 12, 3];
  const [connectionData, setConnectionData] = useState<ConnectionData>({
    typeRequestTo: "",
    id: "",
    message: "",
    files: [],
  });

  


  useEffect(() => {
    handleApplyFilter();
    dispatch(getFavoriteProvidersAsync());
    pageDispatch(changePartnerBase("All"));
  }, [filterValues.page, filterValues.size]);

  const totalPages = allProviders.totalPages;

  const handlePageChange = (newPage: number) => {
    setFilterValues((prevValues) => ({ ...prevValues, page: newPage - 1 }));
  };

  const goToPreviousPage = () => {
    if (!allProviders.first) {
      setFilterValues((prevValues) => ({
        ...prevValues,
        page: prevValues.page! - 1,
      }));
    }
  };

  const goToNextPage = () => {
    if (!allProviders.last) {
      setFilterValues((prevValues) => ({
        ...prevValues,
        page: prevValues.page! + 1,
      }));
    }
  };


  // const startIndex = (currentPage - 1) * companiesPerPage;
  // const endIndex = currentPage * companiesPerPage;

  // const companiesOnCurrentPage: PartnerBaseProvider[] = allProviders.slice(
  //   startIndex,
  //   endIndex
  // );


  const [showCompaniesPerPageOptions, setShowCompaniesPerPageOptions] =
    useState(false);

  const handleCompaniesPerPageOptionToggle = () => {
    setShowCompaniesPerPageOptions(!showCompaniesPerPageOptions);
  };

  const handleCompaniesPerPageChange = (value: number) => {
    setFilterValues((prevValues) => ({ ...prevValues, page: 0, size: value }));
    setSelectedCompaniesPerPage(value);
    setShowCompaniesPerPageOptions(false);
  };

  const handleSortOptionToggle = () => {
    setShowSortOptions(!showSortOptions);
  };

  const handleSortOptionSelect = (option: string) => {
    setSelectedSortOption("Sort by ");
    setSelectedSortCategory(option);
    setShowSortOptions(false);
    const sort =
      option === "Location"
        ? "providerContactInfoCountry"
        : option.toLowerCase();
    setFilterValues((prevValues) => ({ ...prevValues, sort: sort }));
  };

  const handleCloseDialog = () => {
    setIsOpenDialog(false);
  };

  const handleOpenDialog = (provider: PartnerBaseProvider) => {
    setSelectedCompany(provider);
    setIsOpenDialog(true);
    const role = provider.roles && provider.roles.find((value) =>
      value.name.includes("PROVIDER")
    )?.name;
    if (role) {
      setConnectionData((prevData) => ({
        ...prevData,
        typeRequestTo: role!,
        id: provider.id.toString(), 
        message: "",
        files: [],
      }));
    } else {

    }
  };
  
  useEffect(() => {
    if (selectedCompany && token) {
      setConnectionData((prevData) => ({
        ...prevData,
        id: selectedCompany.id.toString(),
      }));
    }
  }, [selectedCompany, token]);
  const handleAttachmentFilesChange = async (filesArray: File[]) => {
    setUploading(true);
    setFilesToUpload(filesArray);
    const saveFiles = await dispatch(
      uploadFilesToServer({ files: filesArray }) as any
    );
    if (saveFiles.payload.message === "400") {
      saveFiles.payload = [];
    }
    const saveFilesPayload: Attachment[] = saveFiles.payload;
    const modifiedFilesArray: ModifiedFileObject[] = saveFilesPayload.map(
      (obj) => {
        const { link, name } = obj;
        return { link, name };
      }
    );
    setConnectionData({ ...connectionData, files: modifiedFilesArray } as any);
    setUploading(false);
  };

  // useEffect(() => {
  //   if (isOpenDialog) {
  //     setMessageFocus(true);
  //   }
  // }, [isOpenDialog]);

  const sendToBackend = (data: ConnectionData) => {
    dispatch(createBusinessRequestsAsync(data)).then(() => {
      dispatch(getAllRequestsAsync() as any)
      setIsOpenDialog(false);
      handleCloseDialog();
      setConnectionData({
        typeRequestTo: "",
        id: "",
        message: "",
        files: [],
      });
    });
  };

  const handleSendButtonClick = () => {
    sendToBackend(connectionData);
    setShowSendMessage((prevShowSendMessage) => !prevShowSendMessage);
    setTimeout(() => {
      setShowSendMessage((prevShowSendMessage) => !prevShowSendMessage);
    }, 3000);
  };



  useEffect(() => {
    if (selectedSortCategory) {
      handleApplyFilter();
    }
  }, [selectedSortCategory]);

  useEffect(() => {
    setCurrentPage(filterValues.page! + 1);
  }, [filterValues.page]);

  return (
    <>
      <SortContainer>
        <SortWrapper>
          {!showSortOptions ? (
            <>
              <div>
                <SortButton onClick={handleSortOptionToggle}>
                  {selectedSortOption}
                </SortButton>
                <ChevronRightSortWrapper onClick={handleSortOptionToggle}>
                  <ChevronRightIcon />
                </ChevronRightSortWrapper>
              </div>
            </>
          ) : (
            <SortActiveWrapper>
              <SortActiveButton onClick={handleSortOptionToggle}>
                {selectedSortOption}
                <ChevronDownSortWrapper onClick={handleSortOptionToggle}>
                  <ChevronDownIcon />
                </ChevronDownSortWrapper>
              </SortActiveButton>
              <IndustryLocationWrapper>
                <LocationIndustryButton
                  onClick={() => handleSortOptionSelect("Industry")}
                >
                  Industry
                </LocationIndustryButton>
                <LocationIndustryButton
                  onClick={() => handleSortOptionSelect("Location")}
                >
                  Location
                </LocationIndustryButton>
              </IndustryLocationWrapper>
            </SortActiveWrapper>
          )}
        </SortWrapper>
      </SortContainer>

      <Container>
        {page === "All" &&
          allProviders.content &&
          allProviders.content.map((provider: PartnerBaseProvider,index) => (
            <PartnerBaseCardToList key={index}
              provider={provider}
              handleOpenDialog={handleOpenDialog}
            />
          ))}

        {page === "Saved" &&
          favoriteProviders &&
          favoriteProviders.map((provider: PartnerBaseProvider) => (
            <PartnerBaseCardToList
              provider={provider}
              handleOpenDialog={handleOpenDialog}
            />
          ))}

        {showSendMessage && (
          <SaveWrapper>
            <SaveParagraph>
              The message has been successfully send.
            </SaveParagraph>
            <CrossWhiteIconWrapper
              onClick={() => setShowSendMessage(!showSendMessage)}
            >
              <CrossWhiteIcon />
            </CrossWhiteIconWrapper>
          </SaveWrapper>
        )}
      </Container>

      {searchValue !== '' && allProviders.content?.length === 0 && <NoResultsMessage>No search results</NoResultsMessage>}

      <PaginationContainer>
        <PaginationBlock>
          <ArrowLeftIcon onClick={goToPreviousPage} />
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
          />
          <ArrowRightIcon onClick={goToNextPage} />
        </PaginationBlock>

        <PageWrapper>
          <PageBlock>
            <TextPage>Factories per page:</TextPage>
            {!showCompaniesPerPageOptions && (
              <PageButton onClick={handleCompaniesPerPageOptionToggle}>
                {selectedCompaniesPerPage}
                <ChevronRightWrapper>
                  <ChevronRightIcon
                    onClick={handleCompaniesPerPageOptionToggle}
                  />
                </ChevronRightWrapper>
              </PageButton>
            )}
          </PageBlock>
          {showCompaniesPerPageOptions && (
            <PageActiveButton>
              {itemsPerPageOptions.map((option) => (
                <PageActiveItem
                  key={option}
                  onClick={() => handleCompaniesPerPageChange(option)}
                >
                  {option}
                </PageActiveItem>
              ))}
              <ChevronUpWrapper>
                <ChevronUpIcon onClick={handleCompaniesPerPageOptionToggle} />
              </ChevronUpWrapper>
            </PageActiveButton>
          )}
        </PageWrapper>
      </PaginationContainer>

      {isOpenDialog && <Overlay onClick={handleCloseDialog} />}
      {isOpenDialog && (
        <>
          <Overlay onClick={handleCloseDialog} />
          <DialogContent>
            
            <ConnectWrapper>
              <Tittle>Business requests</Tittle>

              {selectedCompany && (
                <NameLogoWrapper>
                  <Avatar src={selectedCompany.avatar || defaultAvatar} />
                  <ConnectParagraph>
                    {selectedCompany.companyName}
                  </ConnectParagraph>
                </NameLogoWrapper>
              )}
              <YourMessageWrapper>
                <ConnectParagraph>Your message</ConnectParagraph>
                <RedStar>*</RedStar>
              </YourMessageWrapper>
              <Message
                name="Message"
                id="Mesage"
                cols={30}
                rows={10}
                placeholder="Enter your message"
                autoFocus
                value={connectionData.message}
                onChange={(e) =>
                  setConnectionData({
                    ...connectionData,
                    message: e.target.value,
                  })
                }
              />
              <MediaUploadWrapper>
                {uploading ? (
                  <div>Loading files...</div>
                ) : (
                  <MediaUpload
                    selectedFiles={filesToUpload}
                    onFilesChange={handleAttachmentFilesChange}
                  />
                )}
              </MediaUploadWrapper>
              <ButtonCancelSendWrapper>
                <CancelButton onClick={handleCloseDialog}>Cancel</CancelButton>
                <ButtonSendWrapper>
                  <Button
                    text={"Send"}
                    onClick={handleSendButtonClick}
                    disabled={uploading}
                  />
                </ButtonSendWrapper>
              </ButtonCancelSendWrapper>
            </ConnectWrapper>
          </DialogContent>
        </>
      )}
    </>
  );
};

export default PartnerBaseList;
