import styled from "styled-components";

export const IconContainer = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: rotate(90deg);
`;

export const ChevronRightWrapper = styled.div`
  position: absolute;
  right: 10px;
  bottom: 16px;
`;

export const SwitchButton = styled.button<{
  $isActive?: boolean;
}>`
  position: relative;
  display: flex;
  padding: 14px 30px 16px 30px;
  justify-content: center;
  align-items: center;
  gap: 10px;

  border-radius: 16px;
  border: 1px solid var(--border, #bdbdbd);
  background: var(--wiht, #fff);

  color: var(--txt-dark, #001731);
  font-size: 17px;
  font-weight: 400;
  line-height: 140%;

  background-color: ${({ $isActive }) =>
    $isActive ? "var(--batton, #0095C0)" : "var(--wiht, #FFF)"};
  color: ${({ $isActive }) =>
    $isActive ? " var(--wiht, #FFF)" : "var(--txt-dark, #001731)"};
`;

export const SwitchContainer = styled.div`
  margin-top: 30px;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const FilterByP = styled.p`
  color: var(--txt-dark, #001731);
  font-size: 24px;
  font-weight: 500;
  line-height: 140%;
  margin-bottom: 30px;
`;

export const MainContainer = styled.div`
  margin-left: 30px;
`;

export const BasicInformation = styled.div`
  position: relative;
  display: flex;
  padding: 14px 20px 16px 30px;
  align-items: center;
  gap: 20px;
  align-self: stretch;
  border-radius: 16px;
  border: 1px solid var(--border, #bdbdbd);
  background: var(--wiht, #fff);
  margin-bottom: 30px;
  width: 340px;
  cursor: pointer;
`;

export const BasicInformationOpen = styled.div`
  position: relative;
  display: flex;
  padding: 14px 20px 16px 30px;
  align-items: center;
  gap: 20px;
  align-self: stretch;
  border-radius: 16px;
  background: var(--wiht, #fff);
`;

export const ButtonInformation = styled.button`
  color: var(--txt-dark, #001731);
  font-size: 17px;
  font-weight: 500;
  line-height: 140%;
`;

export const ButtonClear = styled.button`
  color: var(--txt-dark, #001731);
  font-size: 17px;
  font-weight: 500;
  line-height: 140%;
  margin: 0 auto;
`;

export const BasicInformationWrapper = styled.div`
  border-radius: 16px;
  border: 1px solid var(--border, #bdbdbd);
  background: var(--wiht, #fff);
  margin-bottom: 30px;
`;

export const ClearWrapper = styled.div`
  position: relative;
  display: flex;
  padding: 14px 20px 16px 30px;
  align-items: center;
  gap: 20px;
  align-self: stretch;
  border-radius: 16px;
  border: 1px solid var(--border, #bdbdbd);
  background: var(--wiht, #fff);
  margin-bottom: 30px;
  cursor: pointer;
  color: var(--txt-dark, #001731);
  font-size: 17px;
  font-weight: 500;
  line-height: 140%;
  justify-content: center;
  &:hover {
  background: var(
    --hover,
    linear-gradient(254deg, #0095c0 17.54%, rgba(3, 182, 42, 0.6) 90.58%)
  );
  color: var(--txt-light-blue, #f4ffff);

  &:active {
    background: var(--green, #03b62a);
    color: var(--txt-light-blue, #f4ffff);
  }
}
`;