import styled from "styled-components";
import { Link } from "react-router-dom";

export const Container = styled.div``;

export const PageHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;

  margin-bottom: 30px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;
  max-width: 700px;
  width: 100%;
  margin-bottom: 30px;
`;

export const ChevronRightSortWrapper = styled.div`
  position: relative;
  left: 85px;
  bottom: 43px;
`;

export const ChevronDownSortWrapper = styled.div`
  position: absolute;
  left: 60px;
  top: 0px;
`;

export const SearchInputWrapper = styled.div`
  display: flex;
  align-items: center;
  max-width: 778px;
  width: 100%;
  margin-right: 30px;
`;

export const FilterWrapper = styled.div`
  display: inline-flex;
  padding: 14px 20px 16px 0px;
  align-items: flex-start;
  gap: 20px;
  border-radius: 16px;
  border: 1px solid var(--battn-border, #004c62);
  background: var(--wiht, #fff);
`;

export const Paragraph = styled.p`
  color: var(--txt-dark, #001731);
  font-size: 17px;
  font-weight: 500;
  line-height: 140%;
`;

export const PageHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 30px;
`;

export const SwitchButton = styled.button<{
  isActive?: boolean;
}>`
  display: flex;
  padding: 14px 30px 16px 30px;
  justify-content: center;
  align-items: center;
  gap: 10px;

  border-radius: 16px;
  border: 1px solid var(--border, #bdbdbd);
  background: var(--wiht, #fff);

  color: var(--txt-dark, #001731);
  font-size: 17px;
  font-weight: 400;
  line-height: 140%;

  background-color: ${({ isActive }) =>
    isActive ? "var(--batton, #0095C0)" : "var(--wiht, #FFF)"};
  color: ${({ isActive }) =>
    isActive ? " var(--wiht, #FFF)" : "var(--txt-dark, #001731)"};
`;

export const GreenDot = styled.div`
  position: absolute;
  right: 0px;
  top: 0px;
  width: 15px;
  height: 15px;
  background-color: var(--green, #03b62a);
  border-radius: 50%;
  border: 1px solid;
`;

export const SortWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  position: absolute;
  right: 20;
  top: -37px;
  z-index: 2;
`;

export const MarkWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  position: absolute;
  right: 20;
  top: -30px;
  z-index: 2;
`;

export const Avatar = styled.img`
  width: 50px;
  height: 50px;
  /* display: flex;
  justify-content: center;
  margin: 0 auto;
  margin-bottom: 30px;
  object-fit: cover; */
  border-radius: 100px;
`;

export const DefaultAvatar = styled.img`
  width: 500px;
  height: 500px;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  margin-bottom: 30px;
  object-fit: cover;
  border-radius: 100px;
`;

export const Default = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 100px;
`;

export const Subtitle = styled.p`
  color: var(--txt-dak-light, #143255);
  font-size: 38px;
  font-weight: 500;
  line-height: 140%;
  margin-top: 30px;
  margin-bottom: 30px;
`;

export const Input = styled.input`
  border-radius: 16px;
  border: 1px solid var(--border, #bdbdbd);
  background: var(--wiht, #fff);
  display: flex;
  padding: 4px 30px 6px 30px;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
  max-width: 603px;
  width: 100%;
`;

export const KeywordsFilter = styled.div`
  display: grid;
  grid-template-columns: 1.19fr 0.3fr 0.45fr 2.2fr 0.4fr;
  gap: 20px;
  padding-bottom: 10px;

  border-bottom: 1px solid var(--border, #bdbdbd);
`;

export const SubtitleFilter = styled.h3`
  text-align: center;

  color: var(--txt-dark, #001731);
  font-size: 17px;
  font-weight: 500;
  line-height: 140%;
`;

export const CompanyList = styled.div``;

export const FlagComponent = styled.div`
  display: flex;
  align-items: center;
  margin-right: 16px;
`;

export const CompanyComponent = styled.p`
  display: flex;
  margin-right: 16px;
  color: var(--txt-dark, #001731);
  font-size: 17px;
  font-weight: 400;
  line-height: 140%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const CompanySubject = styled.h5`
  display: flex;
  margin-right: 16px;

  font-size: 17px;
  font-weight: 500;
  line-height: 140%;

  color: var(--txt-dark, #001731);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const AvatarComponent = styled.div``;

export const NameComponent = styled.p`
  display: flex;

  color: var(--txt-dark, #001731);
  font-size: 17px;
  font-weight: 400;
  line-height: 140%;
  white-space: nowrap;
`;

export const RatingReviewBlock = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 300px;
  width: 100%;
  gap: 4px;
  grid-column: 2;
`;

export const KeywordComponent = styled.p`
  display: flex;
  max-width: 228px;
  width: 100%;
  color: var(--txt-dak-light, #143255);
  font-size: 14px;
  font-weight: 400;
  line-height: 140%;
`;

export const TypeComponent = styled.h6`
  display: flex;
  width: 121px;
  height: 38px;
  padding: 8px 16px 10px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  grid-column: 3;
  border-radius: 12px;
  border: 1px solid var(--battn-border, #004c62);
  background: var(--gray-light, #fafafa);
  color: var(--txt-dark, #001731);
`;

export const CompanyWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding-left: 30px;
  padding-right: 30px;
  border-bottom: 1px solid var(--border, #bdbdbd);
  position: relative;
  max-height: 88px;
  height: 100%;
  &:hover {
    border-bottom: 1px solid var(--border, #bdbdbd);
    background: var(--bg, #fdfdfd);
    box-shadow: 0px 2px 5.3px 0px rgba(0, 6, 81, 0.5);
  }
`;

export const SelectedMessage = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

export const SelectedText = styled.p`
  color: var(--txt-dak-light, #143255);
  font-size: 17px;
  font-weight: 400;
  line-height: 140%;
  margin-right: 10px;
`;

export const SwitchWrapper = styled.div``;

export const FlagWrapper = styled.div``;

export const MarkButton = styled.button`
  border-radius: 12px;
  border: 1px solid var(--border, #bdbdbd);
  background: var(--wiht, #fff);
  display: flex;
  padding: 8px 16px 10px 16px;
`;

export const MarkParagraph = styled.p`
  color: var(--txt-dark, #001731);
  font-size: 14px;
  font-weight: 400;
  line-height: 140%;
  white-space: nowrap;
`;

export const MoveContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin-top: 20px;
`;

export const MoveWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  position: absolute;
  left: 120px;
  top: -30px;
  z-index: 1;
`;

export const SortContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin-top: 20px;
  z-index: 2;
`;

export const IndustryLocationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
`;

export const BodyMessageLink = styled(Link)`
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 80px 210px 0.4fr 200px 620px 250px;
  //70px 200px 0.6fr 230px 600px 250px;
  gap: 30px;
  grid-template-rows: 1;
  align-items: center;
  padding: 20px 0;
  cursor: pointer;
`;

export const CountryComponent = styled.p`
  grid-column: 4;
  font-size: 17px;
  line-height: 140%;
  white-space: nowrap;
  color: var(--txt-dark-light, #143255);
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const MessageComponent = styled.p`
  display: flex;
  color: var(--txt-dark-light, #143255);

  line-height: 140%;
  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: pre-line;
`;

export const DateComponent = styled.div`
  display: flex;
  align-items: center;
  color: var(--txt-dark, #001731);
  font-size: 17px;
  font-weight: 400;
  line-height: 140%;
  white-space: nowrap;
  margin-left: auto;
`;

export const NotInterestedButton = styled.button`
  color: var(--txt-dak-light, #143255);
  font-size: 17px;
  font-weight: 400;
  line-height: 140%;
  white-space: nowrap;
  &:hover {
    color: var(--txt-blue, #0095c0);
  }
`;

export const DefaultWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 152px;
`;

export const DefaultParagraph = styled.p`
  font-size: 24px;
  font-weight: 500;
  line-height: 140%;
  letter-spacing: 0%;
  text-align: left;
  display: flex;
  justify-content: center;
`;

export const FilterButton = styled.button`
  background: var(--wiht, #fff);
  color: var(--txt-dark, #001731);
  font-size: 17px;
  font-weight: 500;
  line-height: 140%;
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const FilterSortWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  border-radius: 16px;
  border: 1px solid var(--border, #bdbdbd);
  background: var(--wiht, #fff);
  display: flex;
  padding: 14px 20px 10px 30px;
  gap: 10px;
  color: var(--txt-dark, #001731);
  font-size: 17px;
  font-weight: 500;
  line-height: 140%;
`;

export const ChevronFilterWapper = styled.div`
  cursor: pointer;
`;

export const SortButton = styled.button`
  color: var(--txt-dark, #001731);
  font-size: 17px;
  font-weight: 500;
  line-height: 140%;
`;
