import React, { useEffect, useState } from "react";
import { Company, renderStars } from "../../CompanyItem/CompanyItem";
import {
  ButtonCancelSendWrapper,
  ButtonSave,
  ButtonSendWrapper,
  ButtonWrapper,
  CancelButton,
  ChevronDownSortWrapper,
  ChevronRightSortWrapper,
  ChevronRightWrapper,
  ChevronUpWrapper,
  CompanyWrapper,
  CompanyWrapperLink,
  ConnectParagraph,
  ConnectWrapper,
  Container,
  CrossWhiteIconWrapper,
  DialogContent,
  ImageWrapper,
  IndustryLocationWrapper,
  IndustryParagraph,
  IndustryWrapper,
  KeywordsBlock,
  KeywordsContainer,
  KeywordsParagraph,
  KeywordsWrapper,
  LocationIndustryButton,
  LocationWrapper,
  LogoButtonWrapper,
  Message,
  NameLogoWrapper,
  NameWrapper,
  Overlay,
  PageActiveButton,
  PageActiveItem,
  PageBlock,
  PageButton,
  PageWrapper,
  PaginationBlock,
  PaginationContainer,
  Paragraph,
  ParagraphLocation,
  RatingReviewsWrapper,
  RedStar,
  SaveParagraph,
  SaveWrapper,
  SortActiveButton,
  SortActiveWrapper,
  SortButton,
  SortContainer,
  SortWrapper,
  Tittle,
  Up,
  VectorWrapper,
  Wrapper,
  WrapperProvider,
  YourMessageWrapper,
} from "./style";
import Button from "../../Button/Button";
import ArrowLeftIcon from "../../../assets/icons/ArrowLeftIccon";
import Pagination from "../../Pagination/Pagination";
import ArrowRightIcon from "../../../assets/icons/ArrowRightIcon";
import ChevronRightIcon from "../../../assets/icons/ChevronRightIcon";
import ChevronDownIcon from "../../../assets/icons/ChevronDownIcon";
import ChevronUpIcon from "../../../assets/icons/ChevronUpIcon";
import CrossWhiteIcon from "../../../assets/icons/CrossWhireIcon";
import UploadPhotoAgent from "../UploadPhotoAgent/UploadPhotoAgent";
import {
  AgentBaseAgent,
  AgentDetails,
  Attachment,
  ConnectionData,
  ModifiedFileObject,
  Roles,
  allAgentsResponse,
} from "../../../utils/types";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import UnionIcon from "../../../assets/icons/UnionIcon";
import VectorYellowIcon from "../../../assets/icons/VectorYellowIcon";
import { defaultAvatar, defaultSelfman } from "../../../assets/img";
import {
  addAgentToCustomerFavoriteAsync,
  addAgentToProviderFavoriteAsync,
  createBusinessRequestsAsync,
  deleteAgentFromCustomerFavoriteAsync,
  deleteAgentFromProviderFavoriteAsync,
  getFavoriteAgentsAsync,
  uploadFilesToServer,
} from "../../../store/slices/userSlice";
import PartnerBasePage from "../../PartnerBasePage/PartnerBasePage/PartnerBasePage";
import { useDispatch } from "react-redux";
import { changeAgent } from "../../../store/slices/sliceData";
import AgentCardToList from "../AgentCardToList/AgentCardToList";
import {
  Avatar,
  MediaUploadWrapper,
  TextPage,
} from "../../PartnerBasePage/PartnerBaseList/style";

interface AgentListProps {
  filterValues: AgentDetails;
  setFilterValues: React.Dispatch<React.SetStateAction<AgentDetails>>;
  allAgents: allAgentsResponse;
  handleApplyFilter: () => void;
}

const AgentList: React.FC<AgentListProps> = ({
  setFilterValues,
  handleApplyFilter,
  filterValues,
  allAgents,
}) => {
  const dispatch = useAppDispatch();
  const pageDispatch = useDispatch();
  const favoriteAgents = useAppSelector(
    (state) => state.user.customer?.favoriteAgents
  );
  const page = useAppSelector((state) => state.themes.agentFavorites);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedAgentsPerPage, setSelectedAgentsPerPage] = useState(12);
  const [showSortOptions, setShowSortOptions] = useState(false);
  const [selectedSortOption, setSelectedSortOption] = useState("Sort by");
  const [showSaveMessage, setShowSaveMessage] = useState(false);
  const [showSendMessage, setShowSendMessage] = useState(false);
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const [selectedSortCategory, setSelectedSortCategory] = useState<string>("");
  const [selectedAgent, setSelectedAgent] = useState<AgentBaseAgent | null>(
    null
  );
  const [uploading, setUploading] = useState(false);
  const [filesToUpload, setFilesToUpload] = useState<File[]>([]);
  const [connectionData, setConnectionData] = useState<ConnectionData>({
    typeRequestTo: "",
    id: "",
    message: "",
    files: [],
  });
  const itemsPerPageOptions = [48, 36, 24, 12, 3];


  useEffect(() => {
    // handleApplyFilter();
    // dispatch(getFavoriteAgentsAsync());
    // pageDispatch(changeAgent("All"));
  }, [filterValues.page, filterValues.size]);

  // const getButtonStyle = (agentId: number) => {
  //   if (
  //     (user.customer?.favoriteAgents &&
  //       user.customer?.favoriteAgents.includes(agentId)) ||
  //     (user.provider?.favoriteAgents &&
  //       user.provider?.favoriteAgents.includes(agentId))
  //   ) {
  //     return { background: "#03B62A", color: "#fff" };
  //   } else {
  //     return {};
  //   }
  // };

  const totalPages = allAgents.totalPages;

  const handlePageChange = (newPage: number) => {
    setFilterValues((prevValues) => ({ ...prevValues, page: newPage - 1 }));
  };

  const goToPreviousPage = () => {
    if (!allAgents.first) {
      setFilterValues((prevValues) => ({
        ...prevValues,
        page: prevValues.page! - 1,
      }));
    }
  };

  const goToNextPage = () => {
    if (!allAgents.last) {
      setFilterValues((prevValues) => ({
        ...prevValues,
        page: prevValues.page! + 1,
      }));
    }
  };

  const [showCompaniesPerPageOptions, setShowCompaniesPerPageOptions] =
    useState(false);

  const handleCompaniesPerPageOptionToggle = () => {
    setShowCompaniesPerPageOptions(!showCompaniesPerPageOptions);
  };

  const handleCompaniesPerPageChange = (value: number) => {
    setFilterValues((prevValues) => ({ ...prevValues, page: 0, size: value }));
    setSelectedAgentsPerPage(value);
    // setCurrentPage(0);
    setShowCompaniesPerPageOptions(false);
  };

  const handleSortOptionToggle = () => {
    setShowSortOptions(!showSortOptions);
  };

  const handleSortOptionSelect = (option: string) => {
    setSelectedSortOption("Sort by ");
    setSelectedSortCategory(option);
    setShowSortOptions(false);
  };

  const handleCloseDialog = () => {
    setIsOpenDialog(false);
  };

  const handleOpenDialog = (agent: AgentBaseAgent) => {
    setSelectedAgent(agent);
    setIsOpenDialog(true);
    const role = agent.roles.find((value) =>
      value.name.includes("AGENT")
    )?.name;
    setConnectionData((prevData) => ({
      ...prevData,
      typeRequestTo: role!,
      id: agent.id.toString(),
    }));
  };

  const handleFileChange = async (filesArray: File[]) => {
    setUploading(true);
    setFilesToUpload(filesArray);
    const saveFiles = await dispatch(
      uploadFilesToServer({ files: filesArray }) as any
    );
    if (saveFiles.payload.message === "400") {
      saveFiles.payload = [];
    }
    const saveFilesPayload: Attachment[] = saveFiles.payload;
    const modifiedFilesArray: ModifiedFileObject[] = saveFilesPayload.map(
      (obj) => {
        const { link, name } = obj;
        return { link, name };
      }
    );
    setConnectionData({ ...connectionData, files: modifiedFilesArray } as any);
    setUploading(false);
  };

  // useEffect(() => {
  //   if (isOpenDialog) {
  //     setMessageFocus(true);
  //   }
  // }, [isOpenDialog]);

  const sendToBackend = (data: ConnectionData) => {
    dispatch(createBusinessRequestsAsync(data));
    setIsOpenDialog(false);
    handleCloseDialog();
    setConnectionData({
      typeRequestTo: "",
      id: "",
      message: "",
      files: [],
    });
  };

  const handleSendButtonClick = () => {
    // sendToBackend(connectionData);
    setShowSendMessage((prevShowSendMessage) => !prevShowSendMessage);
    setTimeout(() => {
      setShowSendMessage((prevShowSendMessage) => !prevShowSendMessage);
    }, 3000);
  };

  useEffect(() => {
    if (selectedSortCategory) {
    }
  }, [selectedSortCategory]);

  useEffect(() => {
    setCurrentPage(filterValues.page! + 1);
  }, [filterValues.page]);


  return (
    <>
      <SortContainer>
        <SortWrapper>
          {!showSortOptions ? (
            <>
              <div>
                <SortButton onClick={handleSortOptionToggle}>
                  {selectedSortOption}
                </SortButton>
                <ChevronRightSortWrapper onClick={handleSortOptionToggle}>
                  <ChevronRightIcon />
                </ChevronRightSortWrapper>
              </div>
            </>
          ) : (
            <SortActiveWrapper>
              <SortActiveButton onClick={handleSortOptionToggle}>
                {selectedSortOption}
                <ChevronDownSortWrapper onClick={handleSortOptionToggle}>
                  <ChevronDownIcon />
                </ChevronDownSortWrapper>
              </SortActiveButton>
              <IndustryLocationWrapper>
                <LocationIndustryButton
                  onClick={() => handleSortOptionSelect("Category")}
                >
                  Category
                </LocationIndustryButton>
                <LocationIndustryButton
                  onClick={() => handleSortOptionSelect("Location")}
                >
                  Location
                </LocationIndustryButton>
              </IndustryLocationWrapper>
            </SortActiveWrapper>
          )}
        </SortWrapper>
      </SortContainer>

      <Container>
        {page === "All" &&
          allAgents.content &&
          allAgents.content.map((agent: AgentBaseAgent) => (
            <AgentCardToList
              agent={agent}
              handleOpenDialog={handleOpenDialog}
            />
          ))}

        {page === "Saved" &&
          favoriteAgents &&
          favoriteAgents.map((agent: AgentBaseAgent) => (
            <AgentCardToList
              agent={agent}
              handleOpenDialog={handleOpenDialog}
            />
          ))}

        {showSendMessage && (
          <SaveWrapper>
            <SaveParagraph>
              The message has been successfully send.
            </SaveParagraph>
            <CrossWhiteIconWrapper
              onClick={() => setShowSendMessage(!showSendMessage)}
            >
              <CrossWhiteIcon />
            </CrossWhiteIconWrapper>
          </SaveWrapper>
        )}
      </Container>

      <PaginationContainer>
        <PaginationBlock>
          <ArrowLeftIcon onClick={goToPreviousPage} />
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
          />
          <ArrowRightIcon onClick={goToNextPage} />
        </PaginationBlock>

        <PageWrapper>
          <PageBlock>
            <TextPage>Agents per page:</TextPage>
            {!showCompaniesPerPageOptions && (
              <PageButton onClick={handleCompaniesPerPageOptionToggle}>
                {selectedAgentsPerPage}
                <ChevronRightWrapper>
                  <ChevronRightIcon
                    onClick={handleCompaniesPerPageOptionToggle}
                  />
                </ChevronRightWrapper>
              </PageButton>
            )}
          </PageBlock>
          {showCompaniesPerPageOptions && (
            <PageActiveButton>
              {itemsPerPageOptions.map((option) => (
                <PageActiveItem
                  key={option}
                  onClick={() => handleCompaniesPerPageChange(option)}
                >
                  {option}
                </PageActiveItem>
              ))}
              <ChevronUpWrapper>
                <ChevronUpIcon onClick={handleCompaniesPerPageOptionToggle} />
              </ChevronUpWrapper>
            </PageActiveButton>
          )}
        </PageWrapper>
      </PaginationContainer>

      {isOpenDialog && <Overlay onClick={handleCloseDialog} />}
      {isOpenDialog && (
        <>
          <Overlay onClick={handleCloseDialog} />
          <DialogContent>
            <ConnectWrapper>
              <Tittle>Business requests</Tittle>

              {selectedAgent && (
                <NameLogoWrapper>
                  <Avatar src={selectedAgent.avatar || defaultAvatar} />
                  <div>
                    <NameWrapper>
                      <ConnectParagraph>
                        {selectedAgent.firstName}
                      </ConnectParagraph>
                      <ConnectParagraph>
                        {selectedAgent.lastName}
                      </ConnectParagraph>
                    </NameWrapper>
                    <ConnectParagraph>
                      {selectedAgent.companyName}
                    </ConnectParagraph>
                  </div>
                </NameLogoWrapper>
              )}
              <YourMessageWrapper>
                <ConnectParagraph>Your message</ConnectParagraph>
                <RedStar>*</RedStar>
              </YourMessageWrapper>
              <Message
                name="Message"
                id="Mesage"
                cols={30}
                rows={10}
                placeholder="Enter your message"
                autoFocus
                value={connectionData.message}
                onChange={(e) =>
                  setConnectionData({
                    ...connectionData,
                    message: e.target.value,
                  })
                }
              />
              <MediaUploadWrapper>
                {uploading ? (
                  <div>Loading files...</div>
                ) : (
                  <UploadPhotoAgent
                    selectedFiles={filesToUpload}
                    onFilesChange={handleFileChange}
                  />
                )}
              </MediaUploadWrapper>
              <ButtonCancelSendWrapper>
                <CancelButton onClick={handleCloseDialog}>Cancel</CancelButton>
                <ButtonSendWrapper>
                  <Button
                    text={"Send"}
                    onClick={handleSendButtonClick}
                    disabled={uploading}
                  />
                </ButtonSendWrapper>
              </ButtonCancelSendWrapper>
            </ConnectWrapper>
          </DialogContent>
        </>
      )}
    </>
  );
};

export default AgentList;
